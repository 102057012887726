import React from 'react'
import { Link } from 'react-router-dom'
import '../../assets/fonts/font-awesome/font-awesome.css'

const Sidebar = () => {
    return(
        <div className="sidebar">
            <div className="site-menubar">
                <div className="site-menubar-header">
                    <Link to='/'>
                        <img alt="Timbrát" src="/assets/images/logo.svg" />
                    </Link>
                </div>
                <div className="site-menubar-body">
                    <ul className="site-menu">
                        <li className="site-menu-item">
                            <Link to="/facturas"
                                onClick={() => {
                                    window.analytics.track('Ir a Facturas')
                                }}>
                                <i className="site-menu-icon fa-qrcode" aria-hidden="true"></i>
                                <span className="site-menu-title">Facturas</span>
                            </Link>
                        </li>

                        <li className="site-menu-item">
                            <Link to="/clientes"
                            onClick={() => {
                                window.analytics.track('Ir a Clientes')
                            }}>
                                <i className="site-menu-icon bx bx-user" aria-hidden="true"></i>
                                <span className="site-menu-title">Clientes</span>
                            </Link>
                        </li>

                        <li className="site-menu-item">
                            <Link to="/servicios"
                            onClick={() => {
                                window.analytics.track('Ir a Servicios')
                            }}>
                                <i className="site-menu-icon bx bx-barcode" aria-hidden="true"></i>
                                <span className="site-menu-title">Mis conceptos</span>
                            </Link>
                        </li>

                        <li className="site-menu-item">
                            <Link to="/reportes"
                            onClick={() => {
                                window.analytics.track('Ir a Reportes')
                            }}>
                                <i className="site-menu-icon bx bxs-pie-chart-alt-2" aria-hidden="true"></i>
                                <span className="site-menu-title">Reportes</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Sidebar