import React, {Component} from 'react'

class menu extends Component {
	
	render() {
		const {body} = this.props

		return (
			<div ref="propContent">
				{body}
			</div>
		)
	}
}

export default menu