
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { reactLocalStorage } from "reactjs-localstorage"
import toastr from 'toastr'

axios.interceptors.response.use(undefined, function(error) {
    const { response, config } = error
    const newResponse = {}
    const newConfig = {
        url: config.url,
        method: config.method,
        headers: config.headers
    }

    if (config.url != null) {
        newConfig.data = config.data
    }

    if (response != null) {
        if (response.status === 404) {
            return Promise.reject(error)
        }

        if (response.status === 401) {
            toastr.error(`Tu sesión ha expirado`, 'Error')
            window.location.replace('/logout')
        }

        newResponse.status = response.status
        newResponse.statusText = response.statusText
        newResponse.headers = response.headers

        if (response.data != null && response.data.message) {
            newResponse.dataMessage = response.data.message
        }
    }

    return Promise.reject(error)
})

export let baseURL = ''
const hostname = window && window.location && window.location.hostname

switch (hostname) {
    case 'localhost':
      baseURL = 'http://localhost'
      break
    default:
      baseURL = 'https://timbrat-api-4qrulg2e7a-uc.a.run.app'
      break
  }

export class ApiHelper {
    getAuthToken() {
        return reactLocalStorage.get('token') ? reactLocalStorage.get('token') : false
    }

    getBaseURL() {
        return baseURL
    }

    getOutgoingHeaders() {
        return {
            'authorization': this.getAuthToken(),
            'request-id': uuidv4(),
            'Request-Source': 'timbrat_ui'
        }
    }

    async post(url, data) {
        try {
            return await axios.post(url, data, {
                baseURL,
                headers: this.getOutgoingHeaders()
            })
        } catch (error) {
            throw error
        }
    }

    async postWithFiles(url, data) {
        let headers = this.getOutgoingHeaders()
        headers = {
            ...headers,
            'Content-Type': 'multipart/form-data'
        }

        try {
            return await axios.post(url, data, {
                baseURL,
                headers
            })
        } catch (error) {
            throw error
        }
    }

    async put(url, data) {
        try {
            return await axios.put(url, data, {
                baseURL,
                headers: this.getOutgoingHeaders()
            })
        } catch (error) {
            throw error
        }
    }

    async get(url) {
        try {
            return await axios.get(url, {
                baseURL,
                headers: this.getOutgoingHeaders()
            })
        } catch (error) {
            throw error
        }
    }

    async delete(url) {
        try {
            return await axios.delete(url, {
                baseURL,
                headers: this.getOutgoingHeaders()
            })
        } catch (error) {
            throw error
        }
    }
}

export default new ApiHelper()