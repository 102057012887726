import React, { useState, useContext } from 'react'

import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap"

import { withRouter } from "react-router-dom"
import toastr from 'toastr'
import { GeneralContext } from '../../context/'
import ApiService from '../../data/services/ApiService'

const RecoverPassword = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const [email, setEmail] = useState('')

    const _handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        try {
            const response = await ApiService.passwordRecover({ email })
            const { data } = response

            toastr.success(data.message)
        } catch (error) {
            setLoading(false)
            toastr.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <React.Fragment>
            <div className="login">
                <div className="wrapper grid-layout">
                    <Form id="login" type="POST" onSubmit={ _handleSubmit }>
                        <div className="logo">
                            <img alt={`Timbrát`} src="/assets/images/logo.svg" />
                        </div>

                        <div className="form">
                            <h1>Recuperar Contraseña</h1>

                            <Row>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Label htmlFor="email">
                                            Correo Electrónico
                                        </Label>
                                        <Input
                                            id="email"
                                            type="email"
                                            className="form-control"
                                            value={email}
                                            onChange={event => setEmail(event.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className='form-actions'>
                                <Col sm={12}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                    >
                                        Continuar
                                    </Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12} className="login-copyright">
                                    <p>Copyright © {new Date().getFullYear()}. Todos los Derechos Reservados.</p>
                                    <p>Hecho con amor por <a target="_blank" rel="noopener noreferrer" href="https://juvasoft.com/?utm_source=timbrat&utm_medium=login">Juvasoft</a></p>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(RecoverPassword)