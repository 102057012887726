import React from 'react'
import Content from "../Global/content"
import { withRouter } from "react-router-dom"

const Public = (props) => {
    return(
        <React.Fragment>
            <Content body={props.children} />
        </React.Fragment>
    )
}

export default withRouter(Public)