import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import Company from '../company/Index'
import Account from '../account/Index'
import Lugares from '../lugares/Index'
import Users from '../usuarios/Index'
import Certificados from '../certificados/Index'
import Unidades from '../unidades/Index'
import CPS from '../cps/Index'
import Pagos from '../pagos/Index'

import { Card, CardTitle, Row, Col } from 'reactstrap'

const Index = (props) => {
    const { route } = useParams() 
    const [navigation, setNavigation] = useState(<Company />)

    useEffect(() => {
        switch(route) {
            case 'cuenta':
                window.analytics.track('[conf] Ir a Cuenta')
                setNavigation(<Account />)
                break;
            case 'lugares':
                window.analytics.track('[conf] Ir a Lugares')
                setNavigation(<Lugares />)
                break;
            case 'certificados':
                window.analytics.track('[conf] Ir a Certificados')
                setNavigation(<Certificados />)
                break;
            case 'unidades':
                window.analytics.track('[conf] Ir a Unidades')
                setNavigation(<Unidades />)
                break;
            case 'cps':
                window.analytics.track('[conf] Ir a CPS')
                setNavigation(<CPS />)
                break;
            case 'usuarios':
                window.analytics.track('[conf] Ir a Usuarios')
                setNavigation(<Users />)
                break;
            case 'pagos':
                window.analytics.track(`[conf] Ir a Pagos`)
                setNavigation(<Pagos />)
                break;
            case 'empresa':
            default:
                window.analytics.track('[conf] Ir a Empresa')
                setNavigation(<Company />)
                break;
        }
    }, [route])

    return (
        <div className="page configuration-container">
            <Card header="Configuración">
                <CardTitle>Configuraciones</CardTitle>
            </Card>

            <Row className="config">
                <Col className="configuration-nav" md={3} xs={12}>
                    <Card>
                    <ul>
                        <li>
                            <Link to="/configuracion/empresa">Tu Empresa</Link>
                        </li>

                        <li>
                            <Link to="/configuracion/cuenta">Tu Cuenta</Link>
                        </li>

                        <li>
                            <Link to="/configuracion/certificados">Certificados</Link>
                        </li>

                        <li>
                            <Link to="/configuracion/lugares">Lugares de Expedición</Link>
                        </li>

                        <li>
                            <Link to="/configuracion/usuarios">Usuarios</Link>
                        </li>

                        <li>
                            <Link to="/configuracion/unidades">Unidades</Link>
                        </li>

                        <li>
                            <Link to="/configuracion/cps">Claves de Prod / Serv</Link>
                        </li>

                        <li>
                            <Link to="/configuracion/pagos">Pagos</Link>
                        </li>
                    </ul>
                    </Card>
                </Col>

                <Col className="configuration-content" md={9} xs={12}>
                    {navigation}
                </Col>
            </Row>
        </div>
    )
}

export default Index