import React, { useState, useEffect } from 'react'
import Breadcrumbs from '../../components/Global/Breadcrumbs'
import ColumnChart from '../facturas/components/ColumnChart'
import {
    Container,
    Card,
    CardBody,
    Row,
    Col
} from 'reactstrap'

import ApiService from '../../data/services/ApiService'
import CurrencyFormat from 'react-currency-format'
import { numberFormat } from '../../helpers/Currency'

const Index = () => {
    const [year, setYear] = useState(new Date().getFullYear())
    const [totales, setTotales] = useState({
        paid: 0,
        unpaid: 0,
        ticketPromedio: 0
    })
    const [invoicesByYear, setInvoicesByYear] = useState([])
    const [invoicesCount, setInvoicesCount] = useState(0)
    const [series, setSeries] = useState([])
    const categories = ['', "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"]

    useEffect(() => {
        let paid = 0
        let unpaid = 0
        let ticketPromedio = 0
        for (let i = 0; i < invoicesByYear.length; i++) {
            console.log(`item`, invoicesByYear[i].paid)
            paid += invoicesByYear[i].paid
            unpaid += invoicesByYear[i].unpaid
            ticketPromedio += (invoicesByYear[i].paid + invoicesByYear[i].unpaid) / invoicesCount
        }
           
        setTotales({
            paid,
            unpaid,
            ticketPromedio
        })

        // eslint-disable-next-line
    }, [invoicesByYear])

    useEffect(() => {
        (async () => {
            await _loadGraph()
        })()

        window.analytics.page('Reportes')
        // eslint-disable-next-line
    }, [year])

    const _loadGraph = async () => {
        const fromDate = new Date(`${year.toString()}/01/01`)
        const toDate = new Date(`${year.toString()}/12/31`)
        const response = await ApiService.obtenerGraficaAnual({
            fromDate, toDate
        })

        const { data, count } = response.data

        setInvoicesCount(count)
        setInvoicesByYear(data)

        let s = [
            {
                name: 'Facturas Pagadas',
                data: []
            },
            {
                name: 'Facturas Por Cobrar',
                data: []
            },
            {
                name: 'Facturas',
                data: []
            }
        ]

        const months = year === new Date().getFullYear() ? new Date().getMonth() : 12
        for (let i = 0; i <= months; i++) {
            s[0].data[i] = 0
            s[1].data[i] = 0
            s[2].data[i] = 0
        }

        for (let i = 0; i <= months; i++) {
            if (data[i] === undefined) {
                continue
            }

            s[0].data[data[i].month] = numberFormat(data[i].paid, 2, '.', '')
            s[1].data[data[i].month] = numberFormat(data[i].unpaid, 2, '.', '')
            s[2].data[data[i].month] = numberFormat(data[i].total, 2, '.', '')
        }

        setSeries(s)
    }

    const _changeYearInput = (e) => {
        setYear(e.target.value)
    }

    const _getYears = () => {
        const year = new Date().getFullYear()
        let years = []

        for (var i = year; i >= (year - 10); i--) {
            years.push(i)
        }

        return years.map((item, key) => {
            return (<option key={key} value={item}>{item}</option>)
        })
    }

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Reportes"
                        breadcrumbItem={`Estadísticas`}
                        parentLink={`/reportes`}
                    />

                    <Card>
                        <CardBody>
                                <select
                                    onChange={_changeYearInput}
                                    className="custom-select">
                                    { _getYears() }
                                </select>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm={3} style={{ textAlign: "center" }}>
                                    <h1>{invoicesCount}</h1>
                                    <h5>Facturas</h5>
                                </Col>

                                <Col sm={3} style={{ textAlign: "center" }}>
                                    <h1>
                                        <CurrencyFormat
                                            value={totales.paid + totales.unpaid}
                                            displayType="text"
                                            thousandSeparator
                                            prefix="$"
                                            decimalScale={2}
                                        />
                                    </h1>
                                    <h5>Facturación Total</h5>
                                </Col>

                                <Col sm={3} style={{ textAlign: "center" }}>
                                    <h1>
                                        <CurrencyFormat
                                            value={totales.ticketPromedio}
                                            displayType="text"
                                            thousandSeparator
                                            prefix="$"
                                            decimalScale={2}
                                        />
                                    </h1>
                                    <h5>Ticket Promedio</h5>
                                </Col>

                                <Col sm={3} style={{ textAlign: "center" }}>
                                    <h1>
                                        <CurrencyFormat
                                            value={totales.unpaid}
                                            displayType="text"
                                            thousandSeparator
                                            prefix="$"
                                            decimalScale={2}
                                        />
                                    </h1>
                                    <h5>Facturas por Cobrar</h5>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card>
                        {series.length > 0 && (
                            <ColumnChart series={series} categories={categories} />
                        )}
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Index
