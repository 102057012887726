import React, { useState, useEffect, useContext } from 'react'
import { Card, CardBody, CardTitle, Row, Col, Button, Form, Label, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Table } from 'reactstrap'
import ApiService from '../../../data/services/ApiService'
import { GeneralContext } from '../../../context/'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import CurrencyFormat from 'react-currency-format'
import Select from "react-select"
import { numberFormat } from '../../../helpers/Currency'
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { head, isEmpty } from 'lodash'
import Moment from 'moment'
import 'moment/min/locales'

import {
    usoCfdiValores,
    formaPagoValores,
    ObjetoImpCatalogo,
    c_Periodicidad,
    c_Meses
} from '../../../data/catalogs/CatalogosSAT'

Moment.locale('es')

const FormPage = (props) => {

    const impuestos = {
        trasladados: [
            {
                label: 'IVA 16%',
                value: [{
                    tasa: 0.16,
                    tasaLabel: '0.160000',
                    code: '002'
                }]
            },
            {
                label: 'IVA 8%',
                value: [{
                    tasa: 0.08,
                    tasaLabel: '0.080000',
                    code: '002'
                }]
            },
            {
                label: 'IVA 0%',
                value: [{
                    tasa: 0,
                    tasaLabel: '0.000000',
                    code: '002'
                }]
            }
        ],
        retenciones: [
            {
                value: false,
                label: 'N/A'
            },
            {
                label: 'ISR 10%',
                value: [{
                    code: '001',
                    tasa: 0.100000,
                    tasaLabel: '0.100000'
                }]
            },
            {
                label: 'IVA 10.6667%',
                value: [{
                    code: '002',
                    tasa: 0.106667,
                    tasaLabel: '0.106667'
                }]
            },
            {
                label: 'IVA 10.6667% / ISR 1.25%',
                value: [{
                    code: '002',
                    tasa: 0.106667,
                    tasaLabel: '0.106667'
                }, {
                    code: '001',
                    tasa: 0.0125000,
                    tasaLabel: '0.0125000'
                }]
            },
            {
                label: 'IVA 10.6667% / ISR 10%',
                value: [{
                    code: '002',
                    tasa: 0.106667,
                    tasaLabel: '0.106667'
                }, {
                    code: '001',
                    tasa: 0.100000,
                    tasaLabel: '0.100000'
                }]
            }
        ]
    }

    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [lugares, setLugares] = useState([])
    const [clientes, setClientes] = useState([])
    const [servicios, setServicios] = useState([])
    const [certificado, setCertificado] = useState('')
    const [unidades, setUnidades] = useState([])
    const [claves, setClaves] = useState([])

    const [lugarExpedicion, setLugarExpedicion] = useState({})
    const [cliente, setCliente] = useState()
    const [condicionesPago, setCondicionesPago] = useState({
        label: 'Contado',
        value: 'CONTADO'
    })
    const [moneda, setMoneda] = useState({
        label: '(MXN) Pesos Mexicanos',
        value: 'MXN'
    })
    const [tipoCambio, setTipoCambio] = useState()
    const [usoCFDI, setUsoCFDI] = useState({
        value: 'G03',
        label: 'Gastos en general'
    })
    const [formaPago, setFormaPago] = useState({ value: '03', label: 'Transferencia electrónica de fondos' })
    const [pagada, setPagada] = useState({
        label: 'Pagada',
        value: true
    })
    const [metodoPago, setMetodoPago] = useState({
        label: 'PUE - Pago en una sola exhibición',
        value: 'PUE'
    })
    const [lineItems, setLineItems] = useState([])
    const [tempItem, setTempItem] = useState({
        claveProdServ: '',
        descripcion: '',
        claveUnidad: '',
        noIdentificacion: '',
        cantidad: 1,
        valorUnitario: 0,
        importe: 0,
        descuento: 0,
        impuestosTrasladados: 0,
        retenciones: 0,
        total: 0,
        InformacionAduanera: '',
        CuentaPredial: '',
        ObjetoImp: { value: '02', label: '(02) Objeto de impuesto' }
    })

    const [servicio, setServicio] = useState('')
    const [totales, setTotales] = useState({
        subtotal: 0,
        traslados: [],
        retenciones: [],
        total: 0,
        descuentos: 0
    })

    const [informacionGlobal, setInformacionGlobal] = useState({
        Periodicidad: {
            value: '04',
            label: 'Mensual'
        },
        Meses: c_Meses.find(i => i.value === Moment().format('MM')),
        Año: {
            value: Moment().format('Y'),
            label: Moment().format('Y')
        }
    })
    const [showInformacionGlobalForm, setShowInformacionGlobalForm] = useState(false)

    useEffect(() => {
        if (cliente?.rfc === 'XAXX010101000') {
            setShowInformacionGlobalForm(true)
        }

        if (cliente?.rfc !== 'XAXX010101000') {
            setShowInformacionGlobalForm(false)
        }
    }, [cliente])

    useEffect(() => {
        (async () => {
            await fetchLugares()
            await fetchCustomers()
            await fetchServicios()
            await fetchCertificado()
            await fetchUnidades()
            await fetchClaves()
        })()

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        _calculateTotales()

        // eslint-disable-next-line
    }, [lineItems])

    const _calculateTotales = () => {
        let subtotal = 0
        let traslados = []
        let retenciones = []
        let descuentos = 0
        let total = 0

        // eslint-disable-next-line
        lineItems.map((item) => {
            const descuento = parseFloat(item.Descuento) || 0
            const importe = parseFloat(item.ValorUnitario * item.Cantidad)

            subtotal += importe - descuento

            let tit = 0
            let tir = 0
            if (!isEmpty(item.Impuestos)) {
                if (!isEmpty(item.Impuestos.Traslados)) {
                    // eslint-disable-next-line
                    item.Impuestos.Traslados.map((i) => {
                        let label
                        let flag = false
                        if (i.Impuesto === '002') { label = 'IVA' }

                        if (i.Impuesto === '001') { label = 'ISR' }

                        // eslint-disable-next-line
                        traslados.find((item) => {
                            if (item.label === `${label} Trasladado (${i.TasaOCuota * 100}%)`) {
                                item.value += i.Importe
                                flag = true
                            }
                        })

                        if (!flag) {
                            traslados.push({
                                label: `${label} Trasladado (${i.TasaOCuota * 100}%)`,
                                value: i.Importe
                            })
                        }

                        tit += i.Importe
                    })
                }

                if (!isEmpty(item.Impuestos.Retenciones)) {
                    // eslint-disable-next-line
                    item.Impuestos.Retenciones.map((i) => {
                        let label
                        let flag = false
                        if (i.Impuesto === '002') { label = 'IVA' }

                        if (i.Impuesto === '001') { label = 'ISR' }

                        // eslint-disable-next-line
                        retenciones.find((item) => {
                            if (item.label === `${label} Retenido (${i.TasaOCuota * 100}%)`) {
                                item.value += i.Importe
                                flag = true
                            }
                        })

                        if (!flag) {
                            retenciones.push({
                                label: `${label} Retenido (${i.TasaOCuota * 100}%)`,
                                value: i.Importe
                            })
                        }

                        tir += i.Importe
                    })
                }
            }

            total += importe - descuento - tir + tit
            descuentos += descuento
        })

        setTotales({
            subtotal,
            traslados,
            retenciones,
            descuentos,
            total
        })
    }

    const fetchLugares = async () => {
        setLoading(true)

        try {
            const response = await ApiService.getLugares()
            let data = []

            // eslint-disable-next-line
            response.data.data.map((item) => {
                data.push({
                    value: item.cp,
                    label: item.nombre,
                })
            })

            setLugares(data)
            setLugarExpedicion(data[0])
        } catch (error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const fetchCustomers = async () => {
        setLoading(true)

        try {
            const response = await ApiService.getCustomers()
            let data = []
            // eslint-disable-next-line
            response.data.data.map((item) => {
                data.push({
                    id: item._id,
                    value: item._id,
                    label: item.razonSocial,
                    razonSocial: item.razonSocial,
                    rfc: item.rfc,
                    email: item.email || '',
                    residenciaFiscal: item.residenciaFiscal || false,
                    domicilioFiscal: item.cp,
                    regimenFiscal: item.regimenFiscal
                })
            })

            setClientes(data)
        } catch (error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const fetchServicios = async () => {
        setLoading(true)

        try {
            const response = await ApiService.getServices()
            let data = []

            // eslint-disable-next-line
            response.data.data.map((item) => {
                data.push({
                    value: item._id,
                    label: item.descripcion
                })
            })

            setServicios(data)
        } catch (error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const fetchCertificado = async () => {
        setLoading(true)

        try {
            const response = await ApiService.getCertificado()

            if (!response.data.success) {
                toastr.error(`No pude obtener el certificado, asegúrate que si lo tienes agregado.`, 'Falta Certificado')

                return
            }

            setCertificado(response.data.certificado.noCertificado)
        } catch (error) {
            toastr.error(`No pude obtener el certificado`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const fetchUnidades = async () => {
        setLoading(true)

        try {
            const response = await ApiService.getUnidades()
            let data = []

            // eslint-disable-next-line
            response.data.data.map((item) => {
                return data.push({
                    value: item.clave,
                    label: `(${item.clave}) ${item.descripcion}`
                })
            })

            setUnidades(data)
        } catch (error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const fetchClaves = async () => {
        setLoading(true)

        try {
            const response = await ApiService.getClaves()
            let data = []

            // eslint-disable-next-line
            response.data.data.map((item) => {
                return data.push({
                    value: item.clave,
                    label: `(${item.clave}) ${item.descripcion}`
                })
            })

            setClaves(data)
        } catch (error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const _selectCustomer = async (customer) => {
        setCliente(head(clientes.filter(i => i.id === customer.value)))
    }

    const selectService = async (service) => {
        if (service.value === '') { return false }

        setLoading(true)
        const id = service.value

        try {
            const record = await ApiService.getService(id)
            const { data } = record.data

            setTempItem({
                claveProdServ: {
                    label: data.claveProdServ,
                    value: data.claveProdServ
                },
                descripcion: data.descripcion,
                claveUnidad: {
                    value: data.claveUnidad,
                    label: data.claveUnidad
                },
                noIdentificacion: data.noIdentificacion,
                cantidad: 1,
                impuestosTrasladados: tempItem.impuestosTrasladados,
                retenciones: tempItem.retenciones,
                valorUnitario: data.valorUnitario,
                importe: data.valorUnitario,
                descuento: 0,
                ObjetoImp: ObjetoImpCatalogo.find(o => o.value === data.ObjetoImp)
            })
        } catch (error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const _addLineItem = () => {
        if (!_validateConcept()) {
            toastr.error(`No olvides escribir los datos del concepto que deseas facturar`)

            return false
        }

        setLoading(true)

        const descuento = tempItem.descuento / 100
        const { cantidad, valorUnitario } = tempItem
        const importe = valorUnitario * cantidad
        const importeDescontado = importe * descuento
        const subtotal = importe - importeDescontado

        let item = {
            "ClaveProdServ": tempItem.claveProdServ.value,
            "Descripcion": tempItem.descripcion,
            "ClaveUnidad": tempItem.claveUnidad.value,
            "NoIdentificacion": tempItem.noIdentificacion,
            "Cantidad": cantidad,
            "ValorUnitario": numberFormat(valorUnitario, 2, '.', ''),
            "Importe": numberFormat(importe, 2, '.', ''),
            "Descuento": importeDescontado,
            "ObjetoImp": tempItem.ObjetoImp.value
        }

        if ((tempItem.ObjetoImp.value !== '01' || tempItem.ObjetoImp.value !== '03' || tempItem.ObjetoImp.value !== '04') && tempItem.impuestosTrasladados !== '2') {
            item.Impuestos = {}
            item.Impuestos.Traslados = []

            // eslint-disable-next-line
            impuestos.trasladados[tempItem.impuestosTrasladados].value.map((i) => {
                const importe = subtotal * i.tasa

                item.Impuestos.Traslados.push({
                    "Base": numberFormat(subtotal, 2, '.', ''),
                    "Impuesto": i.code,
                    "TipoFactor": "Tasa",
                    "TasaOCuota": i.tasaLabel,
                    "Tasa": i.tasa,
                    "Importe": numberFormat(importe, 2, '.', '')
                })
            })
        }

        if ((tempItem.ObjetoImp.value !== '01' || tempItem.ObjetoImp.value !== '03' || tempItem.ObjetoImp.value !== '04') && tempItem.retenciones !== 0) {
            if (isEmpty(item.Impuestos)) { item.Impuestos = {} }
            item.Impuestos.Retenciones = []

            // eslint-disable-next-line
            impuestos.retenciones[tempItem.retenciones].value.map((i) => {
                const importe = subtotal * i.tasa

                item.Impuestos.Retenciones.push({
                    "Base": numberFormat(subtotal, 2, '.', ''),
                    "Impuesto": i.code,
                    "TipoFactor": "Tasa",
                    "TasaOCuota": i.tasaLabel,
                    "Tasa": i.tasa,
                    "Importe": numberFormat(importe, 2, '.', '')
                })
            })
        }

        if (tempItem.CuentaPredial !== '') {
            item = {
                ...item,
                "CuentaPredial": tempItem.CuentaPredial
            }
        }

        if (tempItem.InformacionAduanera !== '') {
            item = {
                ...item,
                "InformacionAduanera": tempItem.InformacionAduanera
            }
        }

        setLineItems([
            ...lineItems,
            item
        ])

        setTempItem({
            claveProdServ: '',
            descripcion: '',
            claveUnidad: '',
            noIdentificacion: '',
            cantidad: 1,
            valorUnitario: 0,
            importe: 0,
            descuento: 0,
            impuestosTrasladados: 0,
            retenciones: 0,
            total: 0,
            InformacionAduanera: '',
            CuentaPredial: '',
            ObjetoImp: { value: '02', label: '(02) Objeto de impuesto' }
        })

        setServicio('')
        setLoading(false)
    }

    const _validateConcept = () => {
        if (tempItem.claveProdServ === '') { return false }
        if (tempItem.claveUnidad === '') { return false }
        if (tempItem.descripcion === '') { return false }
        if (tempItem.noIdentificacion === '') { return false }
        if (tempItem.cantidad < 1) { return false }
        if (tempItem.valorUnitario < 0.0000000000001) { return false }

        return true
    }

    const _removeItem = (index) => {
        let items = lineItems.filter((v, i) => {
            return i !== index
        })

        setLineItems(items)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!_validateForm()) {
            toastr.error(`No olvides escribir los datos de la factura`)

            return false
        }

        setLoading(true)

        const { profile } = props
        let plantilla = {
            "Version": '4.0',
            "NoCertificado": certificado,
            "Folio": '',
            "Fecha": '',
            "FormaPago": formaPago.value,
            "CondicionesDePago": condicionesPago.value,
            "SubTotal": numberFormat(totales.subtotal + totales.descuentos, 2, '.', ''),
            "Total": numberFormat(totales.total, 2, '.', ''),
            "Moneda": moneda.value,
            "TipoDeComprobante": "I",
            "MetodoPago": metodoPago.value,
            "LugarExpedicion": lugarExpedicion.value,
            "Exportacion": "01", // 01 No Aplica @todo: investigar las demás opciones.
            "Emisor": {
                "Rfc": profile.empresa.rfc.toUpperCase(),
                "Nombre": profile.empresa.razonSocial.toUpperCase(),
                "RegimenFiscal": profile.empresa.regimenFiscal
            },
            "Receptor": {
                "Rfc": cliente.rfc.toUpperCase(),
                "Nombre": cliente.razonSocial.toUpperCase(),
                "UsoCFDI": usoCFDI.value,
                "Email": cliente.email,
                "DomicilioFiscalReceptor": cliente.domicilioFiscal,
                "RegimenFiscalReceptor": cliente.regimenFiscal
            },
            "Conceptos": lineItems,
            "Impuestos": {}
        }

        if (showInformacionGlobalForm) {
            plantilla.InformacionGlobal = {
                Periodicidad: informacionGlobal.Periodicidad.value,
                Meses: informacionGlobal.Meses.value,
                Año: informacionGlobal.Año.value,
            }
        }

        if (moneda.value !== 'MXN' && moneda.value !== 'XXX') {
            plantilla.TipoCambio = tipoCambio
        }

        let traslados = []
        let retenciones = []

        // eslint-disable-next-line
        lineItems.map((item) => {
            if (!isEmpty(item.Impuestos)) {
                if (!isEmpty(item.Impuestos.Traslados)) {
                    item.Impuestos.Traslados.reduce((tax, { Base, TasaOCuota, Importe, Impuesto, TipoFactor }) => {
                        traslados[TasaOCuota] = traslados[TasaOCuota] || { Base: 0, TasaOCuota, Importe: 0, Impuesto, TipoFactor }
                        traslados[TasaOCuota].Base += Base
                        traslados[TasaOCuota].Importe += Importe
                        tax = traslados[TasaOCuota]

                        return tax
                    }, {})
                }

                if (!isEmpty(item.Impuestos.Retenciones)) {
                    item.Impuestos.Retenciones.reduce((tax, { TasaOCuota, Importe, Impuesto, TipoFactor }) => {
                        retenciones[TasaOCuota] = retenciones[TasaOCuota] || { TasaOCuota, Importe: 0, Impuesto, TipoFactor }
                        retenciones[TasaOCuota].Importe += Importe
                        tax = retenciones[TasaOCuota]

                        return tax
                    }, {})
                }
            }
        })

        let tit = 0
        let tir = 0

        // eslint-disable-next-line
        totales.traslados.map(i => { tit += i.value })
        // eslint-disable-next-line
        totales.retenciones.map(i => { tir += i.value })

        if (tit > 0) {
            plantilla.Impuestos.TotalImpuestosTrasladados = numberFormat(tit, 2, '.', '')
            plantilla.Impuestos.Traslados = Object.values(traslados)
        }

        if (tir > 0) {
            plantilla.Impuestos.TotalImpuestosRetenidos = numberFormat(tir, 2, '.', '')
            plantilla.Impuestos.Retenciones = Object.values(retenciones)
        }

        if (cliente.residenciaFiscal) {
            plantilla.Receptor.ResidenciaFiscal = cliente.residenciaFiscal
        }

        if (totales.descuentos > 0) {
            plantilla.Descuento = numberFormat(totales.descuentos, 2, '.', '')
        }

        try {
            const response = await ApiService.timbrarFactura({
                logotipo: profile.empresa.logotipo,
                clienteId: cliente.id,
                plantilla,
                pagada: pagada.value
            })

            if (!response.data.success) {
                toastr.error(response.data.message, 'Error')

                return false
            }

            toastr.success(response.data.message, '¡Éxito!')

            props.history.push('/facturas')
        } catch (error) {
            const { response } = error
            if (response.data && response.data.message) {
                toastr.error(response.data.message, `Tenemos algo que hablar`)

                return
            }

            toastr.error(`Hubo un problema en tu petición, por favor vuelve a intentarlo o contáctanos para ayudarte a resolverlo.`, `Oop's!`)
        } finally {
            setLoading(false)
        }
    }

    const _validateForm = () => {
        if (!cliente) { return false }
        if (lugarExpedicion.length < 1) { return false }
        if (lineItems.length < 1) { return false }
        if (cliente.domicilioFiscal === '') {
            toastr.info(`Debes actualizar los datos fiscales de tu cliente`)

            return false
        }

        return true
    }

    return (
        <React.Fragment>
            <Form id="invoice-create" method="POST" onSubmit={handleSubmit}>
                <Row>
                    <Col sm="12" xs="12">
                        <Card style={{ textAlign: 'right' }}>
                            <CardBody>
                                <Link
                                    className="btn btn-default waves-effect waves-light mb-2 mr-2"
                                    to="/facturas"
                                >
                                    Cancelar
                                </Link>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className={"btn btn-success waves-effect waves-light mb-2 mr-2"}
                                >
                                    <i className={`fa fa-stamp`}></i> TIMBRAR FACTURA
                                </Button>
                            </CardBody>
                        </Card>

                        <Row>
                            <Col xs={12} md={12} lg={4} xl={3}>
                                <Card>
                                    <CardTitle>Generales de la Factura</CardTitle>

                                    <Row>
                                        <Col xs={12} sm={6} lg={12}>
                                            <FormGroup>
                                                <Label htmlFor="metodoPago">
                                                    Cliente
                                                </Label>

                                                <Select
                                                    value={cliente}
                                                    placeholder={`Selecciona un cliente`}
                                                    onChange={(value) => {
                                                        _selectCustomer(value)
                                                    }}
                                                    options={clientes}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} sm={6} lg={12}>
                                            <FormGroup>
                                                <Label htmlFor="usoCFDI">
                                                    Uso del CFDI
                                                </Label>

                                                <Select
                                                    value={usoCFDI}
                                                    placeholder={`Selecciona un uso del CFDi`}
                                                    onChange={(value) => {
                                                        setUsoCFDI(value)
                                                    }}
                                                    options={usoCfdiValores}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} sm={6} lg={12}>
                                            <FormGroup>
                                                <Label htmlFor="metodoPago">
                                                    Método de Pago
                                                </Label>

                                                <Select
                                                    value={metodoPago}
                                                    onChange={(value) => {
                                                        setMetodoPago(value)
                                                    }}
                                                    options={[{
                                                        label: 'PUE - Pago en una sola exhibición',
                                                        value: 'PUE'
                                                    }, {
                                                        label: 'PIP - Pago inicial y parcialidades',
                                                        value: 'PIP'
                                                    }, {
                                                        label: 'PPD - Pago en parcialidades o diferido',
                                                        value: 'PPD'
                                                    }]}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} sm={6} lg={12}>
                                            <FormGroup>
                                                <Label htmlFor="formaPago">
                                                    Forma de Pago
                                                </Label>

                                                <Select
                                                    value={formaPago}
                                                    placeholder={`Selecciona una forma de pago`}
                                                    onChange={(value) => {
                                                        setFormaPago(value)
                                                    }}
                                                    options={formaPagoValores}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} sm={6} lg={12}>
                                            <FormGroup>
                                                <Label htmlFor="condicionesPago">
                                                    Condiciones de Pago
                                                </Label>

                                                <Select
                                                    value={condicionesPago}
                                                    onChange={(value) => {
                                                        setCondicionesPago(value)
                                                    }}
                                                    options={[{
                                                        label: 'Contado',
                                                        value: 'CONTADO'
                                                    }, {
                                                        label: 'Crédito',
                                                        value: 'CREDITO'
                                                    }]}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} sm={6} lg={12}>
                                            <FormGroup>
                                                <Label htmlFor="pagada">
                                                    Status de Pago
                                                </Label>

                                                <Select
                                                    value={pagada}
                                                    onChange={(value) => {
                                                        setPagada(value)
                                                    }}
                                                    options={[{
                                                        label: 'Pagada',
                                                        value: true
                                                    }, {
                                                        label: 'Por cobrar',
                                                        value: false
                                                    }]}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} sm={6} lg={12}>
                                            {showInformacionGlobalForm && (
                                                <>
                                                    <FormGroup>
                                                        <Label htmlFor="periodicidad">
                                                            Periodicidad
                                                        </Label>

                                                        <Select
                                                            value={informacionGlobal.Periodicidad}
                                                            onChange={(value) => {
                                                                setInformacionGlobal({ ...informacionGlobal, Periodicidad: value })
                                                            }}
                                                            options={c_Periodicidad}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label htmlFor="meses">
                                                            Meses
                                                        </Label>

                                                        <Select
                                                            value={informacionGlobal.Meses}
                                                            onChange={(value) => {
                                                                setInformacionGlobal({ ...informacionGlobal, Meses: value })
                                                            }}
                                                            options={c_Meses}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label htmlFor="año">
                                                            Año
                                                        </Label>

                                                        <Select
                                                            value={informacionGlobal.Año}
                                                            onChange={(value) => {
                                                                setInformacionGlobal({ ...informacionGlobal, Año: value })
                                                            }}
                                                            options={[{
                                                                value: Moment().format('Y'),
                                                                label: Moment().format('Y')
                                                            }, {
                                                                value: Moment().subtract(1, 'year').format('Y'),
                                                                label: Moment().subtract(1, 'year').format('Y')
                                                            }]}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </FormGroup>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col xs={12} md={12} lg={8} xl={9}>
                                <Card>
                                    <CardTitle>Conceptos de la Factura</CardTitle>

                                    <CardBody>
                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label htmlFor="servicio">
                                                        <span className="invoice-services-option">Mis Productos y Servicios</span>
                                                    </Label>

                                                    <Select
                                                        value={servicio}
                                                        placeholder={`Productos frecuentes`}
                                                        onChange={(value) => {
                                                            selectService(value)
                                                        }}
                                                        options={servicios}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <FormGroup>
                                                    <Label>Descripción</Label>

                                                    <Input id="descripcion"
                                                        type={`text`}
                                                        value={tempItem.descripcion}
                                                        onBlur={(e) => {
                                                            const { value } = e.target
                                                            if (value === '') {
                                                                document.getElementById('descripcion').classList.add('required-error')
                                                            } else {
                                                                document.getElementById('descripcion').classList.remove('required-error')
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            setTempItem({
                                                                ...tempItem,
                                                                descripcion: e.target.value
                                                            })
                                                        }} />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <FormGroup>
                                                    <Label>
                                                        Cantidad
                                                    </Label>

                                                    <Input type="number"
                                                        id="cantidad"
                                                        onChange={(e) => {
                                                            setTempItem({
                                                                ...tempItem,
                                                                cantidad: e.target.value
                                                            })
                                                        }}
                                                        value={tempItem.cantidad} />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <FormGroup>
                                                    <Label>Precio Unitario</Label>

                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>$</InputGroupText>
                                                        </InputGroupAddon>

                                                        <Input id="valorUnitario"
                                                            type="number"
                                                            value={tempItem.valorUnitario}
                                                            onBlur={(e) => {
                                                                const { value } = e.target
                                                                if (value < 1) {
                                                                    document.getElementById('valorUnitario').classList.add('required-error')
                                                                } else {
                                                                    document.getElementById('valorUnitario').classList.remove('required-error')
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                setTempItem({
                                                                    ...tempItem,
                                                                    valorUnitario: e.target.value
                                                                })
                                                            }} />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <FormGroup>
                                                    <Label>No. Identificación</Label>

                                                    <Input id="noIdentificacion"
                                                        value={tempItem.noIdentificacion}
                                                        onBlur={(e) => {
                                                            const { value } = e.target
                                                            if (value === '') {
                                                                document.getElementById('noIdentificacion').classList.add('required-error')
                                                            } else {
                                                                document.getElementById('noIdentificacion').classList.remove('required-error')
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            const { value } = e.target
                                                            setTempItem({
                                                                ...tempItem,
                                                                noIdentificacion: value
                                                            })
                                                        }} />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <FormGroup>
                                                    <Label htmlFor="claveProdServ">
                                                        Clave de Producto
                                                    </Label>

                                                    <Select
                                                        id="claveProdServ"
                                                        value={tempItem.claveProdServ}
                                                        placeholder={`Buscar clave`}
                                                        onChange={(target) => {
                                                            setTempItem({
                                                                ...tempItem,
                                                                claveProdServ: target
                                                            })
                                                        }}
                                                        options={claves}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <FormGroup>
                                                    <Label htmlFor="claveUnidad">
                                                        Clave de Unidad
                                                    </Label>

                                                    <Select
                                                        id="claveUnidad"
                                                        value={tempItem.claveUnidad}
                                                        placeholder={`Buscar clave`}
                                                        onChange={(target) => {
                                                            setTempItem({
                                                                ...tempItem,
                                                                claveUnidad: target
                                                            })
                                                        }}
                                                        options={unidades}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <FormGroup>
                                                    <Label>Objeto de Impuesto</Label>

                                                    <Select
                                                        defaultValue={tempItem.ObjetoImp}
                                                        value={tempItem.ObjetoImp}
                                                        onChange={(value) => {
                                                            setTempItem({
                                                                ...tempItem,
                                                                ObjetoImp: value
                                                            })
                                                        }}
                                                        placeholder={`Seleccionar opción`}
                                                        options={ObjetoImpCatalogo}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <FormGroup>
                                                    <Label>Impuestos Trasladados</Label>

                                                    <select className="custom-select"
                                                        value={tempItem.impuestosTrasladados}
                                                        onChange={(e) => {
                                                            setTempItem({
                                                                ...tempItem,
                                                                impuestosTrasladados: e.target.value
                                                            })
                                                        }}>
                                                        {impuestos.trasladados.map((v, i) => (
                                                            <option key={i} value={i}>{v.label}</option>
                                                        ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>

                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <FormGroup>
                                                    <Label>Impuestos Retenidos</Label>

                                                    <select className="custom-select"
                                                        value={tempItem.retenciones}
                                                        onChange={(e) => {
                                                            setTempItem({
                                                                ...tempItem,
                                                                retenciones: e.target.value
                                                            })
                                                        }}>
                                                        {impuestos.retenciones.map((v, i) => (
                                                            <option key={i} value={i}>{v.label}</option>
                                                        ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} style={{ textAlign: 'right' }}>
                                                <Button
                                                    onClick={_addLineItem}
                                                    color="primary"
                                                    className="btn waves-effect waves-light mb-2 mr-2">
                                                    <i className={`mdi mdi-plus`}></i> Agregar Concepto
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        {lineItems.length > 0 && (
                            <Card>
                                <Table responsive striped>
                                    <thead>
                                        <tr>
                                            <th>Cantidad</th>
                                            <th>Clave SAT</th>
                                            <th>Unidad</th>
                                            <th>No. Identificación</th>
                                            <th>Descripción</th>
                                            <th>Obj. Imp.</th>
                                            {lineItems[0].CuentaPredial && (
                                                <th>Cuenta Predial</th>
                                            )}
                                            {lineItems[0].InformacionAduanera && (
                                                <th>No. Pedimento</th>
                                            )}
                                            <th>Precio</th>
                                            {lineItems.some(li => li.Descuento > 0) && (
                                                <th>Desc.</th>
                                            )}
                                            <th>Importe</th>
                                            <th>...</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {lineItems.map((item, key) => {
                                            const descuento = item.Descuento || 0
                                            const subtotal = (item.ValorUnitario * item.Cantidad) - descuento

                                            return (
                                                <tr key={key}>
                                                    <td>{item.Cantidad}</td>
                                                    <td>{item.ClaveProdServ}</td>
                                                    <td>{item.ClaveUnidad}</td>
                                                    <td>{item.NoIdentificacion}</td>
                                                    <td>{item.Descripcion}</td>
                                                    <td>{item.ObjetoImp}</td>
                                                    {item.CuentaPredial && (
                                                        <td>{item.CuentaPredial}</td>
                                                    )}
                                                    {item.InformacionAduanera && (
                                                        <td>{item.InformacionAduanera}</td>
                                                    )}
                                                    <td>
                                                        <CurrencyFormat
                                                            value={item.ValorUnitario}
                                                            displayType="text"
                                                            thousandSeparator
                                                            prefix="$"
                                                            decimalScale={2}
                                                        />
                                                    </td>

                                                    {lineItems.some(li => li.Descuento > 0) && (
                                                        <td>
                                                            <CurrencyFormat
                                                                value={descuento}
                                                                displayType="text"
                                                                thousandSeparator
                                                                prefix="$"
                                                                decimalScale={2}
                                                            />
                                                        </td>
                                                    )}

                                                    <td>
                                                        <CurrencyFormat
                                                            value={subtotal}
                                                            displayType="text"
                                                            thousandSeparator
                                                            prefix="$"
                                                            decimalScale={2}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Button
                                                            onClick={() => {
                                                                _removeItem(key)
                                                            }}>
                                                            <i className="bx bxs-trash"></i>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                        <tr>
                                            <td colSpan={lineItems.some(li => li.Descuento > 0) ? 7 : 6} style={{ border: "1px solid transparent" }}></td>
                                            <th>Subtotal:</th>
                                            <td className="importe">
                                                <CurrencyFormat
                                                    value={totales.subtotal}
                                                    displayType="text"
                                                    thousandSeparator
                                                    prefix="$"
                                                    decimalScale={2}
                                                />
                                            </td>
                                            <td></td>
                                        </tr>

                                        {totales.descuentos > 0 && (
                                            <tr>
                                                <td colSpan={lineItems.some(li => li.Descuento > 0) ? 7 : 6} style={{ border: "1px solid transparent" }}></td>
                                                <th>Descuento:</th>
                                                <td className="importe">
                                                    <CurrencyFormat
                                                        value={totales.descuentos}
                                                        displayType="text"
                                                        thousandSeparator
                                                        prefix="$"
                                                        decimalScale={2}
                                                    />
                                                </td>
                                                <td></td>
                                            </tr>
                                        )}

                                        {totales.retenciones.length > 0 && totales.retenciones.map((item, i) => (
                                            <tr key={i}>
                                                <td colSpan={lineItems.some(li => li.Descuento > 0) ? 7 : 6} style={{ border: "1px solid transparent" }}></td>
                                                <th>{item.label}</th>
                                                <td className="importe">
                                                    <CurrencyFormat
                                                        value={item.value}
                                                        displayType="text"
                                                        thousandSeparator
                                                        prefix="$"
                                                        decimalScale={2}
                                                    />
                                                </td>
                                                <td></td>
                                            </tr>
                                        ))}

                                        {totales.traslados.length > 0 && totales.traslados.map((item, i) => (
                                            <tr key={i}>
                                                <td colSpan={lineItems.some(li => li.Descuento > 0) ? 7 : 6} style={{ border: "1px solid transparent" }}></td>
                                                <th>{item.label}</th>
                                                <td className="importe">
                                                    <CurrencyFormat
                                                        value={item.value}
                                                        displayType="text"
                                                        thousandSeparator
                                                        prefix="$"
                                                        decimalScale={2}
                                                    />
                                                </td>
                                                <td></td>
                                            </tr>
                                        ))}

                                        <tr>
                                            <td colSpan={lineItems.some(li => li.Descuento > 0) ? 7 : 6} style={{ border: "1px solid transparent" }}></td>
                                            <th>Total:</th>
                                            <td className="importe">
                                                <CurrencyFormat
                                                    value={totales.total}
                                                    displayType="text"
                                                    thousandSeparator
                                                    prefix="$"
                                                    decimalScale={2}
                                                />
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card>
                        )}
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    return { profile: state.Auth.profile }
}

export default connect(
    mapStatetoProps, {}
)(withRouter(FormPage))
