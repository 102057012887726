import React from 'react'
import Account from './Account'

const Index = (props) => {
    return (
        <React.Fragment>
            <Account />
        </React.Fragment>
    )
}

export default Index