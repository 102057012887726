import React, { useState, useEffect, useContext } from 'react'
import { GeneralContext } from '../../../context/'
import ApiService from '../../../data/services/ApiService'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardBody,
    FormGroup,
    Label
} from "reactstrap"

const FormPage = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [id, setId] = useState()
    const [nombre, setNombre] = useState()
    const [calle, setCalle] = useState()
    const [noExterior, setNoExterior] = useState()
    const [noInterior, setNoInterior] = useState()
    const [cp, setCP] = useState()
    const [colonia, setColonia] = useState()

    useEffect(() => {
        if (props.data !== undefined) { 
            const { data } = props

            setId(data._id)
            setNombre(data.nombre)
            setCalle(data.calle)
            setNoExterior(data.noExterior)
            setNoInterior(data.noInterior)
            setCP(data.cp)
            setColonia(data.colonia)
        }

    }, [props])

    const _handleSubmit = async (e) => {
        e.preventDefault()

        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')

            return
        }

        setLoading(true)
        let data = {
            nombre,
            calle,
            noExterior,
            noInterior,
            cp,
            colonia
        }

        if (props.data !== undefined) { 
            data = {
                ...data,
                id
            }

            await _updateLugar(data)
            props.history.push('/configuracion/lugares')

            return false
        }

        await _createLugar(data)
        props.history.push('/configuracion/lugares')
    }

    const _updateLugar = async (data) => {
        setLoading(true)

        try {
            const response = await ApiService.updateLugares(data)

            if (!response.data.success) {
                toastr.error(response.data.message, 'Error')

                return false
            }

            toastr.success(response.data.message, '¡Éxito!')
            window.analytics.track('Lugar de expedición actualizado')
        } catch(error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const _createLugar = async (data) => {
        setLoading(true)

        try {
            const response = await ApiService.createLugares(data)

            if (!response.data.success) {
                toastr.error(response.data.message, 'Error')

                return false
            }

            toastr.success(response.data.message, '¡Éxito!')
            window.analytics.track('Lugar de expedición agregado')
        } catch(error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const _validateForm = () => {
        if (nombre === '') { return false }
        if (cp === '') { return false }

        return true
    }

    return(
        <React.Fragment>
            <Form id="lugares" method="POST" onSubmit={ _handleSubmit }>
                <Row>
                    <Col sm={12}>
                        <Card style={{textAlign: 'right'}}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> Guardar
                                </Button>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label htmlFor="nombreLugar">
                                                Nombre
                                            </Label>

                                            <Input
                                                id="nombreLugar"
                                                name="nombreLugar"
                                                type="text"
                                                className="form-control"
                                                value={nombre}
                                                onChange={event => setNombre(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={2}>
                                        <FormGroup>
                                            <Label>
                                                C.P.
                                            </Label>

                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={cp}
                                                onChange={event => setCP(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>
                                                Colonia
                                            </Label>

                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={colonia}
                                                onChange={event => setColonia(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>
                                                Calle
                                            </Label>

                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={calle}
                                                onChange={event => setCalle(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={2}>
                                        <FormGroup>
                                            <Label>
                                                No. Exterior
                                            </Label>

                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={noExterior}
                                                onChange={event => setNoExterior(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>
                                                No. Interior
                                            </Label>

                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={noInterior}
                                                onChange={event => setNoInterior(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default FormPage