import React, { useState, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import ApiService from '../../data/services/ApiService'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateCompany } from '../../store/actions'
import { Form, FormGroup, Label, Input, Button, Row, Col, Table } from 'reactstrap'

const hostname = window && window.location && window.location.hostname
let stripePromise
switch (hostname) {
    case 'localhost':
        stripePromise = loadStripe('pk_test_AmmH2yRbUj2dudN847bdsEoG00STbHhfct')
        break
    default:
        stripePromise = loadStripe('pk_live_Qwwzo7PC35kHsv30h2Zo1Z3q00Ql6KxNty')
        break
}

const PaymentForm = (props) => {
    const [plans, setPlans] = useState([])
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null)
    const [useExistingPayment, setUseExistingPayment] = useState(false)
    const [loading, setLoading] = useState(false)
    const stripe = useStripe()
    const elements = useElements()

    useEffect(() => {
        setSelectedPlan(props.currentPlan)
        setPlans(props.plans)
        fetchDefaultPaymentMethod()
    }, [])

    const fetchDefaultPaymentMethod = async () => {
        try {
            const response = await ApiService.fetchDefaultPaymentMethod()
            setDefaultPaymentMethod(response.data.paymentMethod)
        } catch (error) {
            console.error('Error fetching default payment method:', error)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (!stripe || !elements) {
            return
        }

        setLoading(true)
        
        let paymentMethodId = null
        if (useExistingPayment && defaultPaymentMethod) {
            paymentMethodId = defaultPaymentMethod.id
        } else {
            const cardElement = elements.getElement(CardElement)
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            })

            if (error) {
                toastr.error(error.message)
                setLoading(false)

                return
            }

            paymentMethodId = paymentMethod.id
        }

        try {
            const response = await ApiService.createSubscription({ paymentMethod: paymentMethodId, planId: selectedPlan._id })
            if (response.data.success) {
                toastr.success(response.data.message)
                props.updateCompany(response.data.empresa)
            } else {
                toastr.error('No pude crear la suscripción')
            }
        } catch (error) {
            toastr.error('Payment failed')
        } finally {
            setLoading(false)
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            {plans && selectedPlan && (
                <>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label for="plan">Seleccionar Plan</Label>
                                <Input
                                    type="select"
                                    name="plan"
                                    id="plan"
                                    defaultValue={selectedPlan._id}
                                    onChange={(e) => setSelectedPlan(plans.find(plan => plan._id === e.target.value))}
                                >
                                    <option value="">Seleccionar un plan</option>
                                    {plans && plans.filter(p => p.nombre !== 'trial').map((plan) => (
                                        <option selected={plan._id === props.empresa.plan ? 'selected' : 'false'} key={plan._id} value={plan._id}>
                                            Timbrát {plan.nombre} - ${plan.precio} MXN
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Table>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            <strong>Estás por suscribirte al plan Timbrát {selectedPlan.nombre}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li>{selectedPlan.timbres} facturas</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <strong>$ {selectedPlan.precio}</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    {defaultPaymentMethod && (
                        <Row style={{ padding: '16px' }}>
                            <Col sm={12}>
                                <FormGroup check>
                                    <Input type="checkbox" checked={useExistingPayment} onChange={() => setUseExistingPayment(!useExistingPayment)} />
                                    {' '}
                                    <Label check>
                                        <span style={{marginLeft: '24px'}}>Usar método de pago existente ({defaultPaymentMethod.card.brand} **** {defaultPaymentMethod.card.last4} Exp {defaultPaymentMethod.card.exp_month} / {defaultPaymentMethod.card.exp_year})</span>
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    )}
                    {!useExistingPayment && (
                        <Row style={{ padding: '16px' }}>
                            <Col sm={12}>
                                <FormGroup>
                                    <Label for="cardElement">Tarjeta de Crédito o Débito</Label>
                                    <CardElement id="cardElement" />
                                </FormGroup>
                            </Col>
                        </Row>
                    )}
                    <Row className='form-actions'>
                        <Col sm={12}>
                            <Button
                                type="submit"
                                color="primary"
                                style={{ padding: '16px' }}
                                className="btn btn-success waves-effect waves-light mb-2 mr-2"
                                disabled={!stripe || loading}
                            >
                                <>{loading ? 'Procesando...' : `Confirmar suscripción`}</>
                            </Button>
                        </Col>
                    </Row>
                </>
            )}
        </Form>
    )
}

const mapStatetoProps = (state) => {
    const { empresa } = state.Auth.profile

    return { empresa }
}

const ConnectedPaymentForm = connect(
    mapStatetoProps, { updateCompany }
)(withRouter(PaymentForm))

const StripeWrapper = (props) => (
    <Elements stripe={stripePromise}>
        <ConnectedPaymentForm { ...props } />
    </Elements>
)

export default StripeWrapper
