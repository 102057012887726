import React, { useState, useEffect, useContext } from 'react'
import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardBody,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label
} from "reactstrap"

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import Select from "react-select"
import { GeneralContext } from '../../../context/'
import ApiService from '../../../data/services/ApiService'

const FormPage = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [unidades, setUnidades] = useState([])
    const [claves, setClaves] = useState([])

    const ObjetoImpCatalogo = [{
        value: '01',
        label: '(01) Excento de impuestos'
    }, {
        value: '02',
        label: '(02) Objeto de impuesto'
    }, {
        value: '03',
        label: '(03) Objeto del impuesto y no obligado al desglose'
    }]

    const [id, setId] = useState()
    const [noIdentificacion, setNoIdentificacion] = useState('')
    const [claveProdServ, setClaveProdServ] = useState('')
    const [claveUnidad, setClaveUnidad] = useState('')
    const [valorUnitario, setValorUnitario] = useState('')
    const [descripcion, setDescripcion] = useState('')
    const [ObjetoImp, setObjetoImp] = useState({
        value: '02',
        label: '(02) Objeto de impuesto'
    })

    useEffect(() => {
        (async () => {
            await fetchUnidades()
            await fetchClaves()
        })()
    }, [])

    useEffect(() => {
        if (props.data !== undefined) {
            const { data } = props

            setId(data._id)
            setNoIdentificacion(data.noIdentificacion)
            setValorUnitario(data.valorUnitario)
            setDescripcion(data.descripcion)

            const clave = claves.find(item => {
                return item.value === data.claveProdServ
            })

            setClaveProdServ(clave)

            const unidad = unidades.find(item => {
                return item.value === data.claveUnidad
            })

            setClaveUnidad(unidad)

            const oi = ObjetoImpCatalogo.find(item => {
                return item.value === data.ObjetoImp
            })

            setObjetoImp(oi)
        }
    }, [props])

    // useEffect(() => {
    //     const clave = claves.find(item => {
    //         return item.value === props.data.claveProdServ
    //     })

    //     setClaveProdServ(clave)
    // }, [claves])

    // useEffect(() => {
    //     console.log(unidades)

    //     const u = unidades.find(item => {
    //         return item.value === props.data.claveUnidad
    //     })

    //     setClaveUnidad(u)
    //     // eslint-disable-next-line
    // }, [unidades])

    const fetchUnidades = async () => {
        setLoading(true)

        try {
            const response = await ApiService.getUnidades()
            let data = []
            response.data.data.map((item) => {
                return data.push({
                    value: item.clave,
                    label: `(${item.clave}) ${item.descripcion}`
                })
            })

            setUnidades(data)
        } catch (error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const fetchClaves = async () => {
        setLoading(true)

        try {
            const response = await ApiService.getClaves()
            let data = []
            response.data.data.map((item) => {
                return data.push({
                    value: item.clave,
                    label: `(${item.clave}) ${item.descripcion}`
                })
            })

            setClaves(data)
        } catch (error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')

            return
        }

        setLoading(true)

        let data = {
            noIdentificacion,
            claveProdServ: claveProdServ.value,
            claveUnidad: claveUnidad.value,
            valorUnitario,
            descripcion,
            ObjetoImp: ObjetoImp.value
        }

        if (props.data !== undefined) {
            data = {
                ...data,
                id
            }

            await _updateService(data)
            props.history.push('/servicios')

            return false
        }

        await _createService(data)

        props.history.push('/servicios')
    }

    const _createService = async (data) => {
        setLoading(true)

        try {
            const response = await ApiService.createService(data)

            if (!response.data.success) {
                toastr.error(`No pude agregar el servicio`, 'Error')

                return false
            }

            window.analytics.track('Servicio Agregado')
            toastr.success(response.data.message, '¡Éxito!')
        } catch (error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const _updateService = async (data) => {
        setLoading(true)

        try {
            const response = await ApiService.updateService(id, data)

            if (!response.data.success) {
                toastr.error(response.data.message, 'Error')

                return false
            }

            window.analytics.track('Servicio Actualizado')
            toastr.success(response.data.message, '¡Éxito!')
        } catch (error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const _validateForm = () => {
        if (descripcion === '') { return false }
        if (claveUnidad === '') { return false }
        if (claveProdServ === '') { return false }
        if (valorUnitario === '') { return false }
        if (noIdentificacion === '') { return false }

        return true
    }

    return (
        <React.Fragment>
            <Form id="create" method="POST" onSubmit={handleSubmit}>
                <Row>
                    <Col xs={12}>
                        <Card style={{ textAlign: 'right' }}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> Guardar
                                </Button>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label htmlFor="claveProdServ">
                                                Clave del SAT
                                            </Label>

                                            <Select
                                                id="claveProdServ"
                                                value={claveProdServ}
                                                placeholder={`Escribe para buscar`}
                                                onChange={(target) => {
                                                    setClaveProdServ(target)
                                                }}
                                                options={claves}
                                                classNamePrefix="select2-selection"
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label htmlFor="claveUnidad">
                                                Unidad
                                            </Label>

                                            <Select
                                                id="claveUnidad"
                                                value={claveUnidad}
                                                placeholder={`Escribe para buscar`}
                                                onChange={(target) => {
                                                    setClaveUnidad(target)
                                                }}
                                                options={unidades}
                                                classNamePrefix="select2-selection"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>
                                                No. Identificación
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={noIdentificacion}
                                                onChange={event => setNoIdentificacion(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>Valor Unitario</Label>

                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>$</InputGroupText>
                                                </InputGroupAddon>

                                                <Input id="valorUnitario"
                                                    type="number"
                                                    value={valorUnitario}
                                                    onChange={(e) => {
                                                        setValorUnitario(e.target.value)
                                                    }} />
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>Objeto de Impuesto</Label>

                                            <Select
                                                defaultValue={ObjetoImp}
                                                value={ObjetoImp}
                                                onChange={(value) => {
                                                    setObjetoImp(value)
                                                }}
                                                placeholder={`Seleccionar opción`}
                                                options={ObjetoImpCatalogo}
                                                classNamePrefix="select2-selection"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Label>
                                                Descripción
                                            </Label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                value={descripcion}
                                                rows={4}
                                                onChange={event => setDescripcion(event.target.value)}>
                                            </textarea>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default FormPage
