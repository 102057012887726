import React, { useState, useEffect } from 'react'
import Breadcrumbs from '../../components/Global/Breadcrumbs'
import { Container, Card, Row, Col } from 'reactstrap'
import Datatable from './components/Datatable'
import ColumnChart from './components/ColumnChart'
import ApiService from '../../data/services/ApiService'
import CurrencyFormat from 'react-currency-format'
import { numberFormat } from '../../helpers/Currency'

const Index = () => {
    const [year, setYear] = useState(new Date().getFullYear())
    const [totalFacturado, setTotalFacturado] = useState(0)
    const [invoicesPerYear, setInvoicesPerYear] = useState([])
    const [series, setSeries] = useState([])
    const categories = ['', "Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"]

    useEffect(() => {
        let total = 0
        for (var i = 0; i < invoicesPerYear.length; i++) {
            total += invoicesPerYear[i].total
        }
    
        setTotalFacturado(total)
    }, [invoicesPerYear])

    useEffect(() => {
        (async () => {
            await _loadGraph()
        })()

        // eslint-disable-next-line
    }, [year])

    const _loadGraph = async () => {
        const fromDate = new Date(`${year.toString()}/01/01`)
        const toDate = new Date(`${year.toString()}/12/31`)
        const response = await ApiService.obtenerGraficaAnual({ fromDate, toDate })
        const { data } = response.data

        let s = [
            {
                name: 'Facturas Pagadas',
                data: []
            },
            {
                name: 'Facturas Por Cobrar',
                data: []
            },
            {
                name: 'Facturas',
                data: []
            }
        ]

        const months = year === new Date().getFullYear() ? new Date().getMonth() : 12
        for (let i = 0; i <= months; i++) {
            s[0].data[i] = 0
            s[1].data[i] = 0
            s[2].data[i] = 0
        }

        for (let i = 0; i <= months; i++) {
            if (data[i] === undefined) {
                continue
            }

            s[0].data[data[i].month] = numberFormat(data[i].paid, 2, '.', '')
            s[1].data[data[i].month] = numberFormat(data[i].unpaid, 2, '.', '')
            s[2].data[data[i].month] = numberFormat(data[i].total, 2, '.', '')
        }

        setSeries(s)
        setInvoicesPerYear(data)
    }

    const _changeYearInput = (e) => {
        setYear(e.target.value)
    }

    const _getYears = () => {
        const year = new Date().getFullYear()
        let years = []

        for (var i = year; i >= (year - 10); i--) {
            years.push(i)
        }

        return years.map((item, key) => {
            return (<option key={key} value={item}>{item}</option>)
        })
    }

    return(
        <React.Fragment>        
            <Container fluid>
                <Breadcrumbs
                    title="Facturación"
                    breadcrumbItem={`Facturas`}
                    parentLink={`/facturas`}
                />

                <Card>
                    <Row>
                        <Col sm={8}>
                            {series.length > 0 && (
                                <ColumnChart series={series} categories={categories} />
                            )}
                        </Col>

                        <Col sm={4} className="graph-totals">
                            <strong>Total facturado en {year}</strong> <br />
                            <span>
                                <CurrencyFormat
                                    value={totalFacturado}
                                    displayType="text"
                                    thousandSeparator
                                    prefix="$"
                                    decimalScale={2}
                                />
                            </span>

                            <select
                                onChange={_changeYearInput}
                                className="custom-select">
                                { _getYears() }
                            </select>
                        </Col>
                    </Row>
                </Card>

                <Datatable year={year} />
            </Container>
        </React.Fragment>
    )
}

export default Index