import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardTitle, Table } from 'reactstrap'
import * as moment from 'moment'
import ApiService from '../../data/services/ApiService'
import toastr from 'toastr'
import CurrencyFormat from 'react-currency-format'

const Invoices = (props) => {
    const [invoices, setInvoices] = useState([])

    useEffect(() => {
        (async () => {
            try {
                const response = await ApiService.getInvoicesFromStripe({
                    paymentProviderCustomerId: props.empresa.paymentProviderCustomerId
                })

                console.log(`INVOICES`, response.data.invoices)
                setInvoices(response.data.invoices)
            } catch (error) {
                toastr.error('Error fetching invoices')
            }
        })()
    }, [])

    return (
        <>
            <Card>
                <CardTitle>Recibos de facturas pagadas</CardTitle>

                <Table>
                    {invoices && invoices.map(i => (
                        <tr>
                            <td>{moment.unix(i.created).format('DD/MM/YYYY')}</td>
                            <td>
                                <CurrencyFormat
                                    value={i.amount / 100}
                                    displayType="text"
                                    thousandSeparator
                                    prefix="$"
                                    decimalScale={2}
                                />
                            </td>
                            <td>
                                <a
                                    target='_blank'
                                    className='btn btn-secondary' href={i.receipt_url}>
                                    <i className='fa fa-download'></i>
                                </a>
                            </td>
                        </tr>
                    ))}
                </Table>
            </Card>
        </>
    )
}

const mapStatetoProps = (state) => {
    const { empresa } = state.Auth.profile

    return { empresa }
}

export default connect(
    mapStatetoProps, {}
)(withRouter(Invoices))