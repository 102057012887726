import React, { useState, useEffect, useContext, useRef } from 'react'
import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardBody,
    FormGroup,
    Label
} from "reactstrap"

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import Select from "react-select"
import { GeneralContext } from '../../../context/'
import ApiService from '../../../data/services/ApiService'
import Countries from '../../../data/countries'
import { validateRFC, validateEmail } from '../../../components/Global/helpers'
import CodigosPostales from '../../../data/CodigosPostales'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { isEmpty, head } from 'lodash'
import regimenFiscales from '../../../data/RegimenFiscales'

const FormPage = (props) => {
    const generalContext = useContext(GeneralContext)
    const { loading, setLoading } = generalContext

    const autosuggestRef = useRef(null)
    const [autosuggest, setAutosuggest] = useState()

    const [id, setId] = useState()
    const [razonSocial, setRazonSocial] = useState('')
    const [rfc, setRFC] = useState('')
    const [regimenFiscal, setRegimenFiscal] = useState()
    const [calle, setCalle] = useState('')
    const [noExterior, setNoExterior] = useState('')
    const [noInterior, setNoInterior] = useState('')
    const [colonia, setColonia] = useState('')
    const [cp, setCP] = useState('')
    const [ciudad, setCiudad] = useState('')
    const [estado, setEstado] = useState('')
    const [pais, setPais] = useState(false)
    const [telefono, setTelefono] = useState('')
    const [email, setEmail] = useState('')
    const [tags, setTags] = useState('')
    const [notas, setNotas] = useState('')
    const [residenciaFiscal, setResidenciaFiscal] = useState(false)
    const [residenciaFiscalDisabled, setResidenciaFiscalToggle] = useState(true)

    useEffect(() => {
        if (props.data !== undefined) { 
            const { data } = props

            setId(data._id)
            setRazonSocial(data.razonSocial)
            setRFC(data.rfc)
            setCalle(data.calle)
            setNoExterior(data.noExterior)
            setNoInterior(data.noInterior)
            setColonia(data.colonia)
            setCP(data.cp)
            setCiudad(data.ciudad)
            setEstado(data.estado)
            setTelefono(data.telefono)
            setEmail(data.email)
            setTags(data.tags)
            setNotas(data.notas)

            const rf = Countries.find(item => {
                return item.value === data.residenciaFiscal
            })

            const country = Countries.find(item => {
                return item.value === data.pais
            })

            const regfis = regimenFiscales.find(item => {
                return item.value === data.regimenFiscal
            })

            if (data.pais || data.pais !== undefined) {
                setPais(country)
            }

            setResidenciaFiscal(rf)
            setRegimenFiscal(regfis)
            setResidenciaFiscalToggle(data.residenciaFiscal ? false : true)
        }

    }, [props])

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')

            return
        }

        setLoading(true)

        let data = {
            razonSocial,
            rfc,
            regimenFiscal: regimenFiscal.value,
            calle,
            noExterior,
            noInterior,
            colonia,
            cp,
            ciudad,
            estado,
            pais: pais ? pais.value : '',
            telefono,
            email,
            tags,
            notas,
            residenciaFiscal: residenciaFiscal ? residenciaFiscal.value : ''
        }

        if (props.data !== undefined) { 
            data = {
                ...data,
                id
            }

            await _updateCustomer(data)

            return false
        }
        
        await _createCustomer(data)
        
        props.history.push('/clientes')
    }

    const _createCustomer = async (data) => {
        setLoading(true)

        try {
            const response = await ApiService.createCustomer(data)

            if (!response.data.success) {
                toastr.error(`No pude crear a ${razonSocial}.`, 'Error')

                return false
            }

            toastr.success(response.data.message, '¡Éxito!')
            window.analytics.track('Cliente agregado', { data })
        } catch(error) {
            toastr.error(`No pude crear a ${razonSocial}.`, 'Error')
        } finally {
            setLoading(false)
        }
    }

    const _updateCustomer = async (data) => {
        setLoading(true)

        try {
            const response = await ApiService.updateCustomer(id, data)

            if (!response.data.success) {
                toastr.error(`No pude editar a ${razonSocial}.`, 'Error')

                return false
            }

            toastr.success(response.data.message, '¡Éxito!')
            window.analytics.track('Cliente actualizado', { data })
        } catch(error) {
            toastr.error(`No pude editar a ${razonSocial}.`, 'Error')
        } finally {
            setLoading(false)
        }
    }

    const _validateForm = () => {
        if (razonSocial === '') { return false }
        if (rfc === '') { return false }
        if (email === '') { return false }
        if (cp === '') { return false }
        if (!regimenFiscal) { 
            document.getElementById('regimenFiscal').style.border = '1px solid red'
            return false 
        }

        if (!validateRFC(rfc)) {
            toastr.error(`El RFC que ingresaste no es válido`, 'Error')
            document.getElementById('rfc').style.border = '1px solid red'

            return false
        }

        return true
    }

    return(
        <React.Fragment>
            <Form id="create" method="POST" onSubmit={ handleSubmit }>
                <Row>
                    <Col xs={12}>
                        <Card style={{textAlign: 'right'}}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-secondary waves-effect waves-light mb-2 mr-2"
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-primary waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> Guardar
                                </Button>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>
                                                Razón Social *
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={razonSocial}
                                                required
                                                onChange={event => setRazonSocial(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>
                                                RFC *
                                            </Label>
                                            <Input
                                                id={'rfc'}
                                                type="text"
                                                className="form-control"
                                                value={rfc}
                                                required
                                                onBlur={event => {
                                                    const { value } = event.target
                                                    if (!validateRFC(value)) {
                                                        toastr.error(`El RFC que ingresaste no es válido`, 'Error')
                                                        document.getElementById('rfc').style.border = '1px solid red'
                                                    } else {
                                                        document.getElementById('rfc').style.border = '1px solid #c7ced5'
                                                    }
                                                }}
                                                onChange={event => {
                                                    const { value } = event.target
                                                    if (value.toUpperCase() === 'XEXX010101000') {
                                                        setResidenciaFiscalToggle(false)
                                                    } else {
                                                        setResidenciaFiscalToggle(true)
                                                    }

                                                    setRFC(value)
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>
                                                Residencia Fiscal *
                                            </Label>
                                            <Select
                                                value={residenciaFiscal}
                                                placeholder={`Selecciona una residencia Fiscal`}
                                                onChange={(value) => { 
                                                    setResidenciaFiscal(value)
                                                }}
                                                isDisabled={residenciaFiscalDisabled}
                                                options={Countries}
                                                classNamePrefix="select2-selection"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={4}>
                                    <FormGroup>
                                        <Label htmlFor="regimenFiscal">
                                            Régimen Fiscal
                                        </Label>
                                        
                                        <Select
                                            id="regimenFiscal"
                                            value={regimenFiscal}
                                            placeholder={`Selecciona un régimen fiscal`}
                                            onChange={(value) => { 
                                                setRegimenFiscal(value)
                                            }}
                                            options={regimenFiscales}
                                            classNamePrefix="select2-selection"
                                        />
                                    </FormGroup>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>
                                                E-Mail
                                            </Label>
                                            <Input
                                                id="email"
                                                type="email"
                                                className="form-control"
                                                value={email}
                                                required
                                                onBlur={event => {
                                                    const { value } = event.target
                                                    if (!validateEmail(value)) {
                                                        toastr.error(`El Email que ingresaste no es válido`, 'Error')
                                                        document.getElementById('email').style.border = '1px solid red'
                                                    } else {
                                                        document.getElementById('email').style.border = '1px solid #c7ced5'
                                                    }
                                                }}
                                                onChange={event => setEmail(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>
                                                Teléfono
                                            </Label>
                                            <Input
                                                type="tel"
                                                pattern="\d{10}"
                                                maxLength={10}
                                                className="form-control"
                                                value={telefono}
                                                onChange={event => setTelefono(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>
                                                Calle
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={calle}
                                                onChange={event => setCalle(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={2}>
                                        <FormGroup>
                                            <Label>
                                                No. Exterior
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={noExterior}
                                                onChange={event => setNoExterior(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={2}>
                                        <FormGroup>
                                            <Label>
                                                No. Interior
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={noInterior}
                                                onChange={event => setNoInterior(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label>
                                                Colonia
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={colonia}
                                                onChange={event => setColonia(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>
                                                C.P.
                                            </Label>

                                            <AsyncTypeahead
                                                filterBy={() => true}
                                                id="cp"
                                                ref={autosuggestRef}
                                                isLoading={loading}
                                                minLength={3}
                                                labelKey="cp"
                                                value={cp}
                                                onChange={(item) => {
                                                    setAutosuggest([])
                                                    const cp = head(item)
                                                    setCP(cp)
                                                }}
                                                onSearch={(keyword) => {
                                                    const data = CodigosPostales.filter((item) => item.substring(0, keyword.length) === keyword)

                                                    if (isEmpty(data)) { autosuggestRef.current.focus() }
                                            
                                                    setAutosuggest(data)
                                                }}
                                                options={autosuggest}
                                                placeholder={!isEmpty(cp) ? cp : "Busca CP en catálogo del SAT..."}
                                                searchText="Buscando..."
                                                promptText="Buscando..."
                                                useCache={true}
                                                caseSensitive
                                                renderMenuItemChildren={(i) => (<span key={i}>{i}</span>)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>
                                                Ciudad
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={ciudad}
                                                onChange={event => setCiudad(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>
                                                Estado
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={estado}
                                                onChange={event => setEstado(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={3}>
                                        <FormGroup>
                                            <Label>
                                                País
                                            </Label>
                                            <Select
                                                value={pais}
                                                placeholder={`Selecciona país`}
                                                onChange={(value) => { 
                                                    setPais(value)
                                                }}
                                                options={Countries}
                                                classNamePrefix="select2-selection"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default FormPage
