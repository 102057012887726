import React from 'react'
import Breadcrumbs from '../../components/Global/Breadcrumbs'
import {
    Container,
} from "reactstrap"

import Form from './components/Form'

const Create = (props) => {
    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Configuraciones / Lugares de Expedición"
                        breadcrumbItem={`Agregar Lugar`}
                        parentLink={`/configuracion/lugares`}
                    />

                    <Form {...props} />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Create
