import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Col,
    Input,
    Card,
    CardTitle,
    CardBody,
    CardFooter,
    UncontrolledTooltip
} from "reactstrap"

import { GeneralContext } from '../../../context'

import { Link } from "react-router-dom"
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import ApiService from '../../../data/services/ApiService'

const Table = () => {

    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        (async () => {
            await fetchData()
        })()

        // eslint-disable-next-line
    }, [])

    const fetchData = async () => {
        setLoading(true)

        try {
            const response = await ApiService.getCertificados()

            setData(response.data.data)
            setFilteredData(response.data.data)
        } catch (error) {
            toastr.error(`No pude comunicarme con el servidor de Timbrát`, 'Problema de Conexión')
        } finally {
            setLoading(false)
        }
    }

    const _filterData = async (e) => {
        const keystroke = e.target.value.toLowerCase()
        const filteredData = data.filter((item) => {
            const noCertificado = item.noCertificado || ''

            return noCertificado.toLowerCase().includes(keystroke)
        })

        setFilteredData(filteredData)
    }

    const _deleteItem = async (id) => {
        setLoading(true)

        try {
            await ApiService.deleteCertificado(id)
            const filtered = await data.filter((v) => { return v._id !== id })

            setData(filtered)
            setFilteredData(filtered)

            toastr.success(`Eliminé un Lugar de Expedición`, '¡Éxito!')
        } catch (error) {
            toastr.error(`No pude comunicarme con el servidor de Timbrát`, 'Problema de Conexión')
        } finally {
            setLoading(false)
        }
    }

    return(
        <React.Fragment>
            <Row className="mb-2">
                <Col sm="4">
                    <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                            <Input type="text" className="form-control"
                                placeholder="Buscar ..."
                                onKeyUp={(e) => _filterData(e)}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                        </div>
                    </div>
                </Col>
                <Col sm="8">
                    <div className="text-sm-right">
                        <Link to={`/certificados/nuevo`} className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2">
                            <i className="mdi mdi-update mr-1"></i> Reemplazar Certificado
                        </Link>
                    </div>
                </Col>
            </Row>

            <Row>
                {filteredData.map(
                    (item, key) => (
                        <Card key={key} sm={6}>
                            <CardTitle>Certificado de Sello Digital</CardTitle>
                            <CardBody>
                                <strong>No. Certificado:</strong> {item.noCertificado} <br />
                                <strong>Fecha de Carga:</strong> {item.createdAt}
                            </CardBody>
                            <CardFooter className="bg-transparent border-top">
                                <div className="d-flex font-size-20">
                                    <div className="flex-fill">
                                        <button
                                            id={'delete'}
                                            type="button"
                                            className="btn btn-link p-0 m-0 d-inline align-baseline"
                                            onClick={() => _deleteItem(item._id)}>
                                            <i className="mdi mdi-trash-can font-size-16 text-danger mr-2"></i> Eliminar

                                            <UncontrolledTooltip placement="top" target={'delete'}>
                                                Eliminar Lugar
                                            </UncontrolledTooltip>
                                        </button>
                                    </div>
                                </div>
                            </CardFooter>
                        </Card>
                    )
                )}
            </Row>
        </React.Fragment>
    )
}

export default Table