import ApiHelper from '../../helpers/ApiHelper'

export class ApiService {

    getBaseURL() {
        return ApiHelper.getBaseURL()
    }

    async login(login) {
        return await ApiHelper.post(`/api/usuarios/login`, login)
    }

    async passwordRecover(data) {
        return await ApiHelper.post(`/api/usuarios/password-recover`, data)
    }

    async validatePasswordRecoverToken(token) {
        return await ApiHelper.get(`/api/usuarios/validate-password-recover-token/${token}`)
    }

    async confirmPasswordRecover(data) {
        return await ApiHelper.post(`/api/usuarios/confirm-password-recover`, data)
    }

    async updatePassword(data) {
        return await ApiHelper.put(`/api/usuarios/update-password`, data)
    }

    async getUsers() {
        return await ApiHelper.get(`/api/usuarios/list`)
    }

    async getUserById(userId) {
        return await ApiHelper.get(`/api/usuarios/get/${userId}`)
    }

    async createUser(user) {
        return await ApiHelper.post(`/api/usuarios/create`, user)
    }

    async updateUser(id, user) {
        return await ApiHelper.put(`/api/usuarios/update/${id}`, user)
    }

    async deleteUser(id) {
        return await ApiHelper.delete(`/api/usuarios/delete/${id}`)
    }

    async uploadUserAvatar(data) {
        return await ApiHelper.postWithFiles(`/api/usuarios/upload-avatar`, data)
    }

    async uploadBrand(data) { 
        return await ApiHelper.postWithFiles(`/api/empresas/subir-logotipo`, data)
    }

    async updateAccount(data) { 
        return await ApiHelper.put(`/api/empresas/update`, data)
    }

    // Lugares
    async getLugares() {
        return await ApiHelper.get(`/api/lugares-expedicion/list`)
    }

    async getLugar(id) {
        return await ApiHelper.get(`/api/lugares-expedicion/get/${id}`)
    }

    async createLugares(data) {
        return await ApiHelper.post(`/api/lugares-expedicion/create`, data)
    }

    async updateLugares(data) {
        return await ApiHelper.put(`/api/lugares-expedicion/update`, data)
    }

    async deleteLugar(id) {
        return await ApiHelper.delete(`/api/lugares-expedicion/delete/${id}`)
    }

    // Facturas
    async getFacturas(fromDate, toDate) {
        return await ApiHelper.get(`/api/facturas/list/${fromDate}/${toDate}`)
    }

    async cancelarFactura(params) {
        return await ApiHelper.post(`/api/facturas/cancelar/`, params)
    }

    async timbrarFactura(data) {
        return await ApiHelper.post(`/api/facturas/timbrar`, data)
    }

    async saveAsDraft(data) {
        return await ApiHelper.post(`/api/facturas/save-draft`, data)
    }

    async obtenerGraficaAnual(data) {
        return await ApiHelper.post(`/api/graficas/obtenerAnual`, data)
    }

    async getTotalesFacturados(data) {
        return await ApiHelper.get(`/api/graficas/totales-facturados`)
    }

    // CUSTOMERS
    async getCustomerById(id) {
        return await ApiHelper.get(`/api/clientes/get/${id}`)
    }

    async getCustomers() {
        return await ApiHelper.get(`/api/clientes/list`)
    }

    async deleteCustomer(id) {
        return await ApiHelper.delete(`/api/clientes/delete/${id}`)
    }

    async createCustomer(data) {
        return await ApiHelper.post(`/api/clientes/create`, data)
    }

    async updateCustomer(id, data) {
        return await ApiHelper.put(`/api/clientes/update/${id}`, data)
    }

    // SERVICES
    async getServices() {
        return await ApiHelper.get(`/api/servicios/list`)
    }

    async getService(id) {
        return await ApiHelper.get(`/api/servicios/get/${id}`)
    }

    async createService(data) {
        return await ApiHelper.post(`/api/servicios/create`, data)
    }

    async updateService(id, data) {
        return await ApiHelper.put(`/api/servicios/update/${id}`, data)
    }

    async deleteService(id) {
        return await ApiHelper.delete(`/api/servicios/delete/${id}`)
    }

    async getUnidades() {
        return await ApiHelper.get(`/api/unidades/list`)
    }

    async createUnidad(data) {
        return await ApiHelper.post(`/api/unidades/create`, data)
    }

    async deleteUnidad(id) {
        return await ApiHelper.delete(`/api/unidades/delete/${id}`)
    }

    async getClaves() {
        return await ApiHelper.get(`/api/cps/list`)
    }

    async searchForUnidadInSAT(keyword) { 
        return await ApiHelper.get(`/api/unidades/search/${keyword}`)
    }

    async createCPS(data) {
        return await ApiHelper.post(`/api/cps/create`, data)
    }

    async deleteCPS(id) {
        return await ApiHelper.delete(`/api/cps/delete/${id}`)
    }

    async searchForCPSInSAT(keyword) { 
        return await ApiHelper.get(`/api/cps/search/${keyword}`)
    }

    async getClavesSAT() {
        return await ApiHelper.get(`/api/cps/sat`)
    }

    async getCertificados() {
        return await ApiHelper.get(`/api/certificados/list`)
    }

    async getCertificado() {
        return await ApiHelper.get(`/api/certificados/get`)
    }

    async deleteCertificado(id) {
        return await ApiHelper.delete(`/api/certificados/delete/${id}`)
    }

    async createCertificado(data) {
        return await ApiHelper.post(`/api/certificados/create`, data)
    }

    async getPlans() {
        return await ApiHelper.get(`/api/planes/get`)
    }

    async createSubscription(paymentData) {
        return await ApiHelper.post(`/api/suscripciones/create`, paymentData)
    }

    async getInvoicesFromStripe(paymentProviderCustomerId) {
        return await ApiHelper.post(`/api/suscripciones/get-invoices`, paymentProviderCustomerId)
    }

    async fetchDefaultPaymentMethod() {
        return await ApiHelper.get(`/api/suscripciones/get-default-payment-method`)
    }
}

export default new ApiService()
