import React, { useState, useEffect } from "react"
import ReactApexChart from "react-apexcharts"

const ColumnChart = (props) => {
    const [options] = useState({
        chart: {
            height: 350,
            type: "line",
            id: 'invoices',
            toolbar: {
                show: 1,
            },
            zoom: {
                enabled: true,
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: props.categories
        },
        colors: ["#2ebb84", "#FF7B79", "#66C2D5"],
        legend: {
            position: "bottom",
        },
        fill: {
            opacity: 1,
        }
    })

    const [series, setSeries] = useState(props.series)

    useEffect(() => {
        setSeries(props.series)
    }, [props.series])

    return(
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="line"
                height="359"
            />
        </React.Fragment>
    )
}

export default ColumnChart