import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from "react"
import Layout from './components/Layout/Index'
import Public from './components/Layout/Public'
import { authProtectedRoutes, publicRoutes } from "./routes/"
import AppRoute from "./routes/route"
import { Switch, BrowserRouter as Router } from "react-router-dom"
import { GeneralContextProvider } from './context'

import "./assets/scss/theme.scss"

Bugsnag.start({
    apiKey: '4d84fd79b48af4fa936bd5b68dae0dc2',
    plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const App = () => {
    return (
        <React.Fragment>
            <GeneralContextProvider>
                <ErrorBoundary>
                    <Router>
                        <Switch>
                            {publicRoutes.map((route, idx) => (
                                <AppRoute
                                    path={route.path}
                                    layout={Public}
                                    component={route.component}
                                    key={idx}
                                    isAuthProtected={false}
                                />
                            ))}

                            {authProtectedRoutes.map((route, idx) => (
                                <AppRoute
                                    path={route.path}
                                    layout={Layout}
                                    component={route.component}
                                    key={idx}
                                    isAuthProtected={true}
                                />
                            ))}
                        </Switch>
                    </Router>
                </ErrorBoundary>
            </GeneralContextProvider>
        </React.Fragment>
    )
}

export default App
