import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import Breadcrumbs from '../../components/Global/Breadcrumbs'
import {
    Container,
} from 'reactstrap'

import Form from './components/Form'
import ApiService from '../../data/services/ApiService'
import { GeneralContext } from '../../context/'

const Edit = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const { id } = useParams()
    const [data, setData] = useState([])

    useEffect(() => {
        (async () => {
            setLoading(true)
            const response = await ApiService.getService(id)
            setLoading(false)
            setData(response.data.data)
        })()

        // eslint-disable-next-line
    }, [])

    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Productos y Servicios"
                        breadcrumbItem={`Editar Servicio`}
                        parentLink={`/servicios`}
                    />

                    <Form data={data} {...props} />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Edit
