import React, { useState, useEffect } from 'react'

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Form, FormGroup, Label, Input, Button, Row, Col, Card } from 'reactstrap'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import ApiService from '../../data/services/ApiService'

const UpdatePassword = (props) => {
    const [password, setPassword] = useState()
    const [newPassword, setNewPassword] = useState()
    const [confirmNewPassword, setConfirmNewPassword] = useState()

    useEffect(() => {
        if (props.error) {
            toastr.error(`No pude actualizar tu password`)

            return
        }

        if (props.success) {
            toastr.success('Actualicé tu contraseña', '¡Listo!')

            return
        }

        // eslint-disable-next-line
    }, [])

    const _doUpdatePassword = async (e) => {
        e.preventDefault()

        if (!_validateForm()) {
            toastr.error(`Olvidaste llenar algunos campos`)

            return false
        }

        if (newPassword !== confirmNewPassword) {
            toastr.error(`Las contraseñas no coinciden`)

            return false
        }

        try {
            const response = await ApiService.updatePassword({
                password,
                newPassword
            })

            const { message, success } = response.data

            if (!success) {
                toastr.error(message)

                return false
            }

            window.analytics.track('Usuario actualizó password')
            toastr.success(message)
        } catch (error) {
            toastr.error(`No pude actualizar tu password`)
        }
    }

    const _validateForm = () => {
        if (password === '') { return false }
        if (newPassword === '') { return false }
        if (confirmNewPassword === '') { return false }

        return true
    }

    return (
        <React.Fragment>
            <Form id="account" type="POST" onSubmit={_doUpdatePassword}>
                <Card>
                    <Row>
                        <h3>Actualizar Password</h3>

                        <Col sm={12}>
                            <FormGroup>
                                <Label>
                                    Contraseña Actual
                                </Label>
                                <Input
                                    type="password"
                                    className="form-control"
                                    value={password}
                                    required
                                    onChange={event => setPassword(event.target.value)}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label>
                                    Nueva Contraseña
                                </Label>
                                <Input
                                    type="password"
                                    className="form-control"
                                    value={newPassword}
                                    required
                                    onChange={event => setNewPassword(event.target.value)}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label>
                                    Confirmar Contraseña
                                </Label>
                                <Input
                                    type="password"
                                    className="form-control"
                                    value={confirmNewPassword}
                                    required
                                    onChange={event => setConfirmNewPassword(event.target.value)}
                                />
                            </FormGroup>

                            <Button
                                type="submit"
                                color="primary"
                                className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                            >
                                <i className="mdi mdi-refresh"></i> Actualizar Password
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </React.Fragment>
    )
}


const mapStatetoProps = (state) => {
    const { error, success } = state.Auth

    return { error, success }
}

export default connect(
    mapStatetoProps, {}
)(withRouter(UpdatePassword))