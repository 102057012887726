import React from 'react'
import Account from './Account'
import ChangePassword from './ChangePassword'

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Row, Col } from 'reactstrap'

const Index = (props) => {
    return(
        <React.Fragment>
            <Row>
                <Col sm={6}>
                    <Account data={props.user} />
                </Col>
                <Col sm={6}>
                    <ChangePassword userId={props.user._id} />
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    const { user } = state.Auth.profile

    return { user }
}

export default connect(
    mapStatetoProps, {}
)(withRouter(Index))