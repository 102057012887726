import React, { useEffect } from 'react'

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { logoutUser } from "../../store/actions"

const Logout = (props) => {
    useEffect(() => {
        props.logoutUser(props.history)
    })

    return (<React.Fragment></React.Fragment>)
}

const mapStatetoProps = () => {
    return {}
}

export default withRouter(
    connect(mapStatetoProps, { logoutUser })(Logout)
)