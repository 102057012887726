import React from 'react'
import { Container } from 'reactstrap'
import Breadcrumbs from '../../components/Global/Breadcrumbs' 
import Table from './components/Table'

const Index = () => {
    return(
        <React.Fragment>
            <div id="mainContent" className="page mainContent">
                <Container fluid>
                    <Breadcrumbs
                        title="Configuraciones"
                        breadcrumbItem={`Certificados`}
                        parentLink={`/configuracion/certificados`}
                    />

                    <Table />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Index