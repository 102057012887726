import React from "react"
import PropTypes from "prop-types"

export const Context = React.createContext({})
export const Provider = (props) => {
    const { children } = props
    const [loading, setLoading] = React.useState(false)

    const objContext = {
        loading,
        setLoading,
    }

    return <Context.Provider value={objContext}>{children}</Context.Provider>
}

Provider.propTypes = {
    children: PropTypes.any,
}

export const { Consumer } = Context
