export default [
    { label: "Afganistán", value: "AFG" },
    { label: "Islas Åland", value: "ALA" },
    { label: "Albania", value: "ALB" },
    { label: "Alemania", value: "DEU" },
    { label: "Andorra", value: "AND" },
    { label: "Angola", value: "AGO" },
    { label: "Anguila", value: "AIA" },
    { label: "Antártida", value: "ATA" },
    { label: "Antigua y Barbuda", value: "ATG" },
    { label: "Arabia Saudita", value: "SAU" },
    { label: "Argelia", value: "DZA" },
    { label: "Argentina", value: "ARG" },
    { label: "Armenia", value: "ARM" },
    { label: "Aruba", value: "ABW" },
    { label: "Australia", value: "AUS" },
    { label: "Austria", value: "AUT" },
    { label: "Azerbaiyán", value: "AZE" },
    { label: "Bahamas (las)", value: "BHS" },
    { label: "Bangladés", value: "BGD" },
    { label: "Barbados", value: "BRB" },
    { label: "Baréin", value: "BHR" },
    { label: "Bélgica", value: "BEL" },
    { label: "Belice", value: "BLZ" },
    { label: "Benín", value: "BEN" },
    { label: "Bermudas", value: "BMU" },
    { label: "Bielorrusia", value: "BLR" },
    { label: "Myanmar", value: "MMR" },
    { label: "Bolivia, Estado Plurinacional de", value: "BOL" },
    { label: "Bosnia y Herzegovina", value: "BIH" },
    { label: "Botsuana", value: "BWA" },
    { label: "Brasil", value: "BRA" },
    { label: "Brunéi Darussalam", value: "BRN" },
    { label: "Bulgaria", value: "BGR" },
    { label: "Burkina Faso", value: "BFA" },
    { label: "Burundi", value: "BDI" },
    { label: "Bután", value: "BTN" },
    { label: "Cabo Verde", value: "CPV" },
    { label: "Camboya", value: "KHM" },
    { label: "Camerún", value: "CMR" },
    { label: "Canadá", value: "CAN" },
    { label: "Catar", value: "QAT" },
    { label: "Bonaire, San Eustaquio y Saba", value: "BES" },
    { label: "Chad", value: "TCD" },
    { label: "Chile", value: "CHL" },
    { label: "China", value: "CHN" },
    { label: "Chipre", value: "CYP" },
    { label: "Colombia", value: "COL" },
    { label: "Comoras", value: "COM" },
    { label: "Corea (la República Democrática Popular de)", value: "PRK" },
    { label: "Corea (la República de)", value: "KOR" },
    { label: "Côte d'Ivoire", value: "CIV" },
    { label: "Costa Rica", value: "CRI" },
    { label: "Croacia", value: "HRV" },
    { label: "Cuba", value: "CUB" },
    { label: "Curaçao", value: "CUW" },
    { label: "Dinamarca", value: "DNK" },
    { label: "Dominica", value: "DMA" },
    { label: "Ecuador", value: "ECU" },
    { label: "Egipto", value: "EGY" },
    { label: "El Salvador", value: "SLV" },
    { label: "Emiratos Árabes Unidos (Los)", value: "ARE" },
    { label: "Eritrea", value: "ERI" },
    { label: "Eslovaquia", value: "SVK" },
    { label: "Eslovenia", value: "SVN" },
    { label: "España", value: "ESP" },
    { label: "Estados Unidos (los)", value: "USA" },
    { label: "Estonia", value: "EST" },
    { label: "Etiopía", value: "ETH" },
    { label: "Filipinas (las)", value: "PHL" },
    { label: "Finlandia", value: "FIN" },
    { label: "Fiyi", value: "FJI" },
    { label: "Francia", value: "FRA" },
    { label: "Gabón", value: "GAB" },
    { label: "Gambia (La)", value: "GMB" },
    { label: "Georgia", value: "GEO" },
    { label: "Ghana", value: "GHA" },
    { label: "Gibraltar", value: "GIB" },
    { label: "Granada", value: "GRD" },
    { label: "Grecia", value: "GRC" },
    { label: "Groenlandia", value: "GRL" },
    { label: "Guadalupe", value: "GLP" },
    { label: "Guam", value: "GUM" },
    { label: "Guatemala", value: "GTM" },
    { label: "Guayana Francesa", value: "GUF" },
    { label: "Guernsey", value: "GGY" },
    { label: "Guinea", value: "GIN" },
    { label: "Guinea-Bisáu", value: "GNB" },
    { label: "Guinea Ecuatorial", value: "GNQ" },
    { label: "Guyana", value: "GUY" },
    { label: "Haití", value: "HTI" },
    { label: "Honduras", value: "HND" },
    { label: "Hong Kong", value: "HKG" },
    { label: "Hungría", value: "HUN" },
    { label: "India", value: "IND" },
    { label: "Indonesia", value: "IDN" },
    { label: "Irak", value: "IRQ" },
    { label: "Irán (la República Islámica de)", value: "IRN" },
    { label: "Irlanda", value: "IRL" },
    { label: "Isla Bouvet", value: "BVT" },
    { label: "Isla de Man", value: "IMN" },
    { label: "Isla de Navidad", value: "CXR" },
    { label: "Isla Norfolk", value: "NFK" },
    { label: "Islandia", value: "ISL" },
    { label: "Islas Caimán (las)", value: "CYM" },
    { label: "Islas Cocos (Keeling)", value: "CCK" },
    { label: "Islas Cook (las)", value: "COK" },
    { label: "Islas Feroe (las)", value: "FRO" },
    { label: "Georgia del sur y las islas sandwich del sur", value: "SGS" },
    { label: "Isla Heard e Islas McDonald", value: "HMD" },
    { label: "Islas Malvinas [Falkland] (las)", value: "FLK" },
    { label: "Islas Marianas del Norte (las)", value: "MNP" },
    { label: "Islas Marshall (las)", value: "MHL" },
    { label: "Pitcairn", value: "PCN" },
    { label: "Islas Salomón (las)", value: "SLB" },
    { label: "Islas Turcas y Caicos (las)", value: "TCA" },
    { label: "Islas de Ultramar Menores de Estados Unidos (las)", value: "UMI" },
    { label: "Islas Vírgenes (Británicas)", value: "VGB" },
    { label: "Islas Vírgenes (EE.UU.)", value: "VIR" },
    { label: "Israel", value: "ISR" },
    { label: "Italia", value: "ITA" },
    { label: "Jamaica", value: "JAM" },
    { label: "Japón", value: "JPN" },
    { label: "Jersey", value: "JEY" },
    { label: "Jordania", value: "JOR" },
    { label: "Kazajistán", value: "KAZ" },
    { label: "Kenia", value: "KEN" },
    { label: "Kirguistán", value: "KGZ" },
    { label: "Kiribati", value: "KIR" },
    { label: "Kuwait", value: "KWT" },
    { label: "Lao, (la) República Democrática Popular", value: "LAO" },
    { label: "Lesoto", value: "LSO" },
    { label: "Letonia", value: "LVA" },
    { label: "Líbano", value: "LBN" },
    { label: "Liberia", value: "LBR" },
    { label: "Libia", value: "LBY" },
    { label: "Liechtenstein", value: "LIE" },
    { label: "Lituania", value: "LTU" },
    { label: "Luxemburgo", value: "LUX" },
    { label: "Macao", value: "MAC" },
    { label: "Madagascar", value: "MDG" },
    { label: "Malasia", value: "MYS" },
    { label: "Malaui", value: "MWI" },
    { label: "Maldivas", value: "MDV" },
    { label: "Malí", value: "MLI" },
    { label: "Malta", value: "MLT" },
    { label: "Marruecos", value: "MAR" },
    { label: "Martinica", value: "MTQ" },
    { label: "Mauricio", value: "MUS" },
    { label: "Mauritania", value: "MRT" },
    { label: "Mayotte", value: "MYT" },
    { label: "México", value: "MEX" },
    { label: "Micronesia (los Estados Federados de)", value: "FSM" },
    { label: "Moldavia (la República de)", value: "MDA" },
    { label: "Mónaco", value: "MCO" },
    { label: "Mongolia", value: "MNG" },
    { label: "Montenegro", value: "MNE" },
    { label: "Montserrat", value: "MSR" },
    { label: "Mozambique", value: "MOZ" },
    { label: "Namibia", value: "NAM" },
    { label: "Nauru", value: "NRU" },
    { label: "Nepal", value: "NPL" },
    { label: "Nicaragua", value: "NIC" },
    { label: "Níger (el)", value: "NER" },
    { label: "Nigeria", value: "NGA" },
    { label: "Niue", value: "NIU" },
    { label: "Noruega", value: "NOR" },
    { label: "Nueva Caledonia", value: "NCL" },
    { label: "Nueva Zelanda", value: "NZL" },
    { label: "Omán", value: "OMN" },
    { label: "Países Bajos (los)", value: "NLD" },
    { label: "Pakistán", value: "PAK" },
    { label: "Palaos", value: "PLW" },
    { label: "Palestina, Estado de", value: "PSE" },
    { label: "Panamá", value: "PAN" },
    { label: "Papúa Nueva Guinea", value: "PNG" },
    { label: "Paraguay", value: "PRY" },
    { label: "Perú", value: "PER" },
    { label: "Polinesia Francesa", value: "PYF" },
    { label: "Polonia", value: "POL" },
    { label: "Portugal", value: "PRT" },
    { label: "Puerto Rico", value: "PRI" },
    { label: "Reino Unido (el)", value: "GBR" },
    { label: "República Centroafricana (la)", value: "CAF" },
    { label: "República Checa (la)", value: "CZE" },
    { label: "Macedonia (la antigua República Yugoslava de)", value: "MKD" },
    { label: "Congo", value: "COG" },
    { label: "Congo (la República Democrática del)", value: "COD" },
    { label: "República Dominicana (la)", value: "DOM" },
    { label: "Reunión", value: "REU" },
    { label: "Ruanda", value: "RWA" },
    { label: "Rumania", value: "ROU" },
    { label: "Rusia, (la) Federación de", value: "RUS" },
    { label: "Sahara Occidental", value: "ESH" },
    { label: "Samoa", value: "WSM" },
    { label: "Samoa Americana", value: "ASM" },
    { label: "San Bartolomé", value: "BLM" },
    { label: "San Cristóbal y Nieves", value: "KNA" },
    { label: "San Marino", value: "SMR" },
    { label: "San Martín (parte francesa)", value: "MAF" },
    { label: "San Pedro y Miquelón", value: "SPM" },
    { label: "San Vicente y las Granadinas", value: "VCT" },
    { label: "Santa Helena, Ascensión y Tristán de Acuña", value: "SHN" },
    { label: "Santa Lucía", value: "LCA" },
    { label: "Santo Tomé y Príncipe", value: "STP" },
    { label: "Senegal", value: "SEN" },
    { label: "Serbia", value: "SRB" },
    { label: "Seychelles", value: "SYC" },
    { label: "Sierra leona", value: "SLE" },
    { label: "Singapur", value: "SGP" },
    { label: "Sint Maarten (parte holandesa)", value: "SXM" },
    { label: "Siria, (la) República Árabe", value: "SYR" },
    { label: "Somalia", value: "SOM" },
    { label: "Sri Lanka", value: "LKA" },
    { label: "Suazilandia", value: "SWZ" },
    { label: "Sudáfrica", value: "ZAF" },
    { label: "Sudán (el)", value: "SDN" },
    { label: "Sudán del Sur", value: "SSD" },
    { label: "Suecia", value: "SWE" },
    { label: "Suiza", value: "CHE" },
    { label: "Surinam", value: "SUR" },
    { label: "Svalbard y Jan Mayen", value: "SJM" },
    { label: "Tailandia", value: "THA" },
    { label: "Taiwán (Provincia de China)", value: "TWN" },
    { label: "Tanzania, República Unida de", value: "TZA" },
    { label: "Tayikistán", value: "TJK" },
    { label: "Territorio Británico del Océano Índico (el)", value: "IOT" },
    { label: "Territorios Australes Franceses (los)", value: "ATF" },
    { label: "Timor-Leste", value: "TLS" },
    { label: "Togo", value: "TGO" },
    { label: "Tokelau", value: "TKL" },
    { label: "Tonga", value: "TON" },
    { label: "Trinidad y Tobago", value: "TTO" },
    { label: "Túnez", value: "TUN" },
    { label: "Turkmenistán", value: "TKM" },
    { label: "Turquía", value: "TUR" },
    { label: "Tuvalu", value: "TUV" },
    { label: "Ucrania", value: "UKR" },
    { label: "Uganda", value: "UGA" },
    { label: "Uruguay", value: "URY" },
    { label: "Uzbekistán", value: "UZB" },
    { label: "Vanuatu", value: "VUT" },
    { label: "Santa Sede[Estado de la Ciudad del Vaticano] (la)", value: "VAT" },
    { label: "Venezuela, República Bolivariana de", value: "VEN" },
    { label: "Viet Nam", value: "VNM" },
    { label: "Wallis y Futuna", value: "WLF" },
    { label: "Yemen", value: "YEM" },
    { label: "Yibuti", value: "DJI" },
    { label: "Zambia", value: "ZMB" },
    { label: "Zimbabue", value: "ZWE" },
    { label: "Países no declarados", value: "ZZZ" }
]
