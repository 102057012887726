import React, { useEffect, useState, useContext } from 'react'
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Input,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    FormGroup,
    Label
} from 'reactstrap'

import Select from "react-select"
import Modal from '../../../components/Modal'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import CurrencyFormat from 'react-currency-format'

import ApiService from '../../../data/services/ApiService'
import { GeneralContext } from '../../../context/'

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const motivosCancelacion = [
    {value: '00', label: 'Selecciona el motivo de cancelación'},
    {value: '01', label: 'Comprobante emitido con errores con relación'},
    {value: '02', label: 'Comprobante emitido con errores sin relación'},
    {value: '03', label: 'No se llevó a cabo la operación'},
    {value: '04', label: 'Operación nominativa relacionada en la factura global'}
]

const Table = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [year, setYear] = useState()
    const [invoices, setInvoices] = useState()
    const [filteredData, setFilteredData] = useState([])
    const [cancelInvoiceModal, setCancelInvoiceModal] = useState(false)
    const [invoiceToCancel, setInvoiceToCancel] = useState()
    const [cancelReason, setCancelReason] = useState()
    const [folioSustitucion, setFolioSustitucion] = useState()
    const [showFolioSustitucion, toggleFolioSustitucion] = useState(false)

    useEffect(() => {
        setYear(props.year)
        // eslint-disable-next-line
    }, [props])

    useEffect(() => {
        (async () => {
            await _getInvoices()
        })()

        // eslint-disable-next-line
    }, [year]) // eslint-disable-line

    const _getInvoices = async () => {
        setLoading(true)

        const y = year || new Date().getFullYear()
        const fromDate = new Date(`${y.toString()}/01/01`)
        const toDate = new Date(`${y.toString()}/12/31`)
        const result = await ApiService.getFacturas(fromDate, toDate)

        setInvoices(result.data.data)
        setFilteredData(result.data.data)
        setLoading(false)
    }

    const _filterInvoices = async (e) => {
        setLoading(true)
        const keystroke = e.target.value.toLowerCase()
        const data = invoices.filter((item) => {
            const uuid = item.uuid || ''
            const folio = item.folio || ''
            const cliente = item.clientes[0].razonSocial || ''
            const status = item.status || ''

            return uuid.toLowerCase().includes(keystroke) || 
                cliente.toLowerCase().includes(keystroke) ||
                folio.toLowerCase().includes(keystroke) ||
                status.toLowerCase().includes(keystroke)
        })

        setFilteredData(data)
        setLoading(false)
    }

    const _cancelInvoice = async () => {
        setLoading(true)
        const response = await ApiService.cancelarFactura({
            uuid: invoiceToCancel,
            motivo: cancelReason.value,
            folioSustitucion
        })

        if (!response.data.success) {
            toastr.error(response.data.message)
            setLoading(false)

            return
        }

        toastr.success(response.data.message)
        setLoading(false)
        window.analytics.track('Factura cancelada', {
            uuid: invoiceToCancel,
            motivo: cancelReason,
            folioSustitucion
        })
    }

    const __renderCancelarFacturaModal = () => {
        return (
            <Modal
                title={`Cancelar Factura ${invoiceToCancel}`}
                isOpen={cancelInvoiceModal}
                body={__renderCancelarFacturaBody()} 
                footer={__renderCancelarFacturaFooter()}
            />
        )
    }

    const __renderCancelarFacturaBody = () => {
        return (
            <React.Fragment>
                <FormGroup>
                    <Label htmlFor="lugarExpedicion">
                        Motivo de Cancelación *
                    </Label>

                    <Select
                        value={cancelReason}
                        placeholder={`Selecciona el motivo de cancelación`}
                        onChange={(value) => { 
                            setCancelReason(value)

                            if (value.value === '01') {
                                toggleFolioSustitucion(true)
                            }

                            if (value.value !== '01') {
                                toggleFolioSustitucion(false)
                            }
                        }}
                        options={motivosCancelacion}
                        classNamePrefix="select2-selection"
                    />
                </FormGroup>

                {showFolioSustitucion && (
                    <FormGroup>
                        <Label>Folio de Nueva Factura:</Label>

                        <Input
                            type={`text`}
                            value={folioSustitucion}
                            placeholder={`Escribe el Folio Fiscal de la nueva factura expedida`}
                            onChange={(event) => {
                                setFolioSustitucion(event.target.value)
                            }}
                         />
                    </FormGroup>
                )}
            </React.Fragment>
        )
    }

    const __renderCancelarFacturaFooter = () => {
        return (
            <React.Fragment>
                <Button color="danger" onClick={_cancelInvoice}>Confirmar Cancelación</Button>
            </React.Fragment>
        )
    }

    return(
        <React.Fragment>
            <Card>
                <CardBody>
                    <Row className="mb-2">
                        <Col sm="4">
                            <div className="search-box mr-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                    <Input type="text" className="form-control"
                                        placeholder="Buscar ..."
                                        onKeyUp={(e) => _filterInvoices(e)}
                                    />
                                    <i className="bx bx-search-alt search-icon"></i>
                                </div>
                            </div>
                        </Col>
                        <Col sm="8">
                            <div className="text-sm-right">
                                <Link to={`/facturas/nueva`} className="btn btn-success waves-effect waves-light mb-2 mr-2">
                                    <i className="mdi mdi-plus mr-1"></i> Nueva Factura
                                </Link>
                            </div>
                        </Col>
                    </Row>

                    {filteredData.map((invoice, key) => (
                        <Card key={key}>
                            <CardTitle style={{ fontSize: '130%' }}>#{invoice.folio} {invoice.clientes[0].razonSocial}</CardTitle>
                            <CardBody>
                                <Row>
                                    <Col sm={5} xs={12}>
                                        <strong>Fecha de Timbrado</strong><br />
                                        <span><small><Moment format="DD/MM/YYYY">{invoice.fechaTimbrado}</Moment></small></span> <br /><br />
                                        <strong>Estado del CFDi:</strong> <span>{invoice.status.toLowerCase().replace(/^\w/, c => c.toUpperCase())}</span><br />
                                        <strong>UUID:</strong> <small>{invoice.uuid.toUpperCase()}</small>
                                    </Col>
                                    <Col sm={5} xs={12}>
                                        <Row>
                                            <Col sm={6} xs={12} style={{
                                                padding: '16px 0',
                                                textAlign: 'left'
                                            }}>
                                                <span className={invoice.pagada === true ? 'invoice-pagada' : 'invoice-porcobrar'}>
                                                    {invoice.pagada === true ? 'Pagada' : 'Pendiente de Pago'}
                                                </span>
                                            </Col>
                                            <Col sm={6} xs={12} style={{
                                                padding: '16px 0',
                                                textAlign: 'right'
                                            }}>
                                                <strong style={{
                                                    fontSize: '180%'
                                                }}>
                                                    <CurrencyFormat
                                                        value={invoice.total}
                                                        displayType="text"
                                                        thousandSeparator
                                                        prefix="$"
                                                        decimalScale={2}
                                                    />
                                                </strong>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={2} xs={12}>
                                        <UncontrolledDropdown>
                                            <DropdownToggle href="#" className="card-drop" tag="i">
                                                <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem target="_blank" href={`${ApiService.getBaseURL()}/api/facturas/get-pdf/${invoice.uuid}`}>
                                                    <i className="bx bxs-file-pdf font-size-16 text-success mr-2"></i> Descargar PDF
                                                </DropdownItem>

                                                <DropdownItem target="_blank" href={`${ApiService.getBaseURL()}/api/facturas/get-xml/${invoice.uuid}`}>
                                                    <i className="bx bxs-file-blank font-size-16 text-success mr-2"></i> Descargar XML
                                                </DropdownItem>

                                                {invoice.status === 'cancelada' && (
                                                    <DropdownItem target="_blank" href={`${ApiService.getBaseURL()}/api/facturas/get-acuse-cancelacion/${invoice.uuid}`}>
                                                        <i className="bx bxs-file-blank font-size-16 text-success mr-2"></i> Obtener Acuse de Cancelación (XML)
                                                    </DropdownItem>
                                                )}

                                                <DropdownItem target="_blank" href={`https://api.whatsapp.com/send?text=¡Hola!+Te+envío+la+factura+PDF:+${ApiService.getBaseURL()}/api/facturas/get-pdf/${invoice.uuid}+y+XML:+${ApiService.getBaseURL()}/api/facturas/get-xml/${invoice.uuid}`}>
                                                    <i className="bx bxl-whatsapp font-size-16 text-success mr-2"></i> Compartir por WhatsApp
                                                </DropdownItem>

                                                <div className="dropdown-divider"></div>

                                                {invoice.status !== 'cancelada' && (
                                                    <DropdownItem href="#"
                                                        onClick={() => {
                                                            setCancelInvoiceModal(true)
                                                            setInvoiceToCancel(invoice.uuid)
                                                        }}>
                                                        <i className="mdi mdi-cancel font-size-16 text-danger mr-2"></i> Cancelar Factura
                                                    </DropdownItem>
                                                )}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    ))}
                </CardBody>
            </Card>

            {cancelInvoiceModal && __renderCancelarFacturaModal()}
        </React.Fragment>
    )
}

export default Table
