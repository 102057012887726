export const usoCfdiValores = [
    {
        value: 'P01',
        label: 'Por Definir'
    },
    {
        value: 'G01',
        label: 'Adquisición de mercancías'
    },
    {
        value: 'G02',
        label: 'Devoluciones, descuentos o bonificaciones'
    },
    {
        value: 'G03',
        label: 'Gastos en general'
    },
    {
        value: 'I01',
        label: 'Construcciones'
    },
    {
        value: 'I02',
        label: 'Mobilario y equipo de oficina por inversiones'
    },
    {
        value: 'I03',
        label: 'Equipo de transporte'
    },
    {
        value: 'I04',
        label: 'Equipo de computo y accesorios'
    },
    {
        value: 'I05',
        label: 'Dados, troqueles, moldes, matrices y herramental'
    },
    {
        value: 'I06',
        label: 'Comunicaciones telefónicas'
    },
    {
        value: 'I07',
        label: 'Comunicaciones satelitales'
    },
    {
        value: 'I08',
        label: 'Otra maquinaria y equipo'
    },
    {
        value: 'D01',
        label: 'Honorarios médicos, dentales y gastos hospitalarios'
    },
    {
        value: 'D02',
        label: 'Gastos médicos por incapacidad o discapacidad'
    },
    {
        value: 'D03',
        label: 'Gastos funerales'
    },
    {
        value: 'D04',
        label: 'Donativos'
    },
    {
        value: 'D05',
        label: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)'
    },
    {
        value: 'D06',
        label: 'Aportaciones voluntarias al SAR'
    },
    {
        value: 'D07',
        label: 'Primas por seguros de gastos médicos'
    },
    {
        value: 'D08',
        label: 'Gastos de transportación escolar obligatoria'
    },
    {
        value: 'D09',
        label: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones'
    },
    {
        value: 'D10',
        label: 'Pagos por servicios educativos (colegiaturas)'
    },
    {
        value: 'S01',
        label: 'Sin efectos fiscales'
    }
]

export const formaPagoValores = [
    {value: '01', label: 'Efectivo'},
    {value: '02', label: 'Cheque nominativo'},
    {value: '03', label: 'Transferencia electrónica de fondos'},
    {value: '04', label: 'Tarjeta de crédito'},
    {value: '05', label: 'Monedero electrónico'},
    {value: '06', label: 'Dinero electrónico'},
    {value: '08', label: 'Vales de despensa'},
    {value: '12', label: 'Dación en pago'},
    {value: '13', label: 'Pago por subrogación'},
    {value: '14', label: 'Pago por consignación'},
    {value: '15', label: 'Condonación'},
    {value: '17', label: 'Compensación'},
    {value: '23', label: 'Novación'},
    {value: '24', label: 'Confusión'},
    {value: '25', label: 'Remisión de deuda'},
    {value: '26', label: 'Prescripción o caducidad'},
    {value: '27', label: 'A satisfacción del acreedor'},
    {value: '28', label: 'Tarjeta de débito'},
    {value: '29', label: 'Tarjeta de servicios'},
    {value: '99', label: 'Por definir'}
]

export const ObjetoImpCatalogo = [{
    value: '01',
    label: '(01) Excento de impuestos'
}, {
    value: '02',
    label: '(02) Objeto de impuesto'
}, {
    value: '03',
    label: '(03) Objeto del impuesto y no obligado al desglose'
}]

export const c_Periodicidad = [{
    value: '01',
    label: 'Diario'
}, {
    value: '02',
    label: 'Semanal'
}, {
    value: '03',
    label: 'Quincenal'
}, {
    value: '04',
    label: 'Mensual'
}, {
    value: '05',
    label: 'Bimestral'
}]

export const c_Meses = [{
    value: '01',
    label: 'Enero'
}, {
    value: '02',
    label: 'Febrero'
}, {
    value: '03',
    label: 'Marzo'
}, {
    value: '04',
    label: 'Abril'
}, {
    value: '05',
    label: 'Mayo'
}, {
    value: '06',
    label: 'Junio'
}, {
    value: '07',
    label: 'Julio'
}, {
    value: '08',
    label: 'Agosto'
}, {
    value: '09',
    label: 'Septiembre'
}, {
    value: '10',
    label: 'Octubre'
}, {
    value: '11',
    label: 'Noviembre'
}, {
    value: '12',
    label: 'Diciembre'
}, {
    value: '13',
    label: 'Enero-Febrero'
}, {
    value: '14',
    label: 'Marzo-Abril'
}, {
    value: '15',
    label: 'Mayo-Junio'
}, {
    value: '16',
    label: 'Julio-Agosto'
}, {
    value: '17',
    label: 'Septiembre-Octubre'
}, {
    value: '18',
    label: 'Noviembre-Diciembre'
}]