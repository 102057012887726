import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    API_ERROR,
    UPDATE_AVATAR,
    UPDATE_PROFILE,
    UPDATE_COMPANY,
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_SUCCESS
} from "./actionTypes"
import { reactLocalStorage } from "reactjs-localstorage"

const initialState = {
    error: "",
    loading: false,
    profile: reactLocalStorage.get('profile')
}

const login = (state = initialState, action) => {
    const { data } = action

    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                loading: true,
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                profile: data,
                loading: false,
            }
        case LOGOUT_USER:
            state = { ...state }
            break
        case LOGOUT_USER_SUCCESS:
            state = { ...state, loading: false }
            break
        case API_ERROR:
            state = { ...state, error: action.payload, loading: false }
            break
        case UPDATE_PASSWORD:
            state = {
                ...state,
                loading: true
            }

            break
        case UPDATE_PASSWORD_SUCCESS:
            state = {
                ...state,
                success: true,
                loading: false
            }

            break
        case UPDATE_AVATAR:
            return {
                ...state,
                profile: { ...state.profile, avatar: data },
                loading: false
            }
        case UPDATE_PROFILE:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    user: data
                }
            }
        case UPDATE_COMPANY:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    empresa: data
                }
            }
        default:
            return { ...state }
    }
    return state
}

export default login
