import React, { useState } from 'react'

import { validateRFC } from '../../components/Global/helpers'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import ApiService from '../../data/services/ApiService'

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { updateCompany } from '../../store/actions'
import Select from "react-select"
import { Form, FormGroup, Label, Input, Button, Row, Col, Card } from 'reactstrap'
import regimenFiscales from '../../data/RegimenFiscales'

const Account = (props) => {
    const rf = regimenFiscales.find(item => {
        return item.value === props.empresa.regimenFiscal
    })

    const [empresaId] = useState(props.empresa._id)
    const [nombre, setNombre] = useState(props.empresa.nombre)
    const [razonSocial, setRazonSocial] = useState(props.empresa.razonSocial)
    const [regimenFiscal, setRegimenFiscal] = useState(rf)
    const [rfc, setRFC] = useState(props.empresa.rfc)
    const [calle, setCalle] = useState(props.empresa.calle)
    const [noExterior, setNoExterior] = useState(props.empresa.noExterior)
    const [noInterior, setNoInterior] = useState(props.empresa.noInterior)
    const [colonia, setColonia] = useState(props.empresa.colonia)
    const [cp, setCP] = useState(props.empresa.cp)
    const [ciudad, setCiudad] = useState(props.empresa.ciudad)
    const [estado, setEstado] = useState(props.empresa.estado)
    const [pais, setPais] = useState(props.empresa.pais)
    const [telefono, setTelefono] = useState(props.empresa.telefono)
    const [email, setEmail] = useState(props.empresa.email)
    const [logotipo, setLogotipo] = useState(props.empresa.logotipo)

    const _handleSubmit = async (e) => {
        e.preventDefault()

        if (!validateRFC(rfc)) {
            toastr.error(`El RFC que escribiste no es correcto`)

            return false
        }

        if (!_validateForm()) {
            toastr.error(`Olvidaste llenar algunos campos`)

            return false
        }

        const company = {
            nombre,
            razonSocial,
            regimenFiscal: regimenFiscal.value,
            rfc,
            calle,
            noExterior,
            noInterior,
            colonia,
            logotipo,
            cp,
            ciudad,
            estado,
            pais,
            telefono,
            email
        }

        props.updateCompany({
            ...company,
            _id: empresaId
        })

        const response = await ApiService.updateAccount(company)

        if (!response.data.success) {
            toastr.error('No pude actualizar tus datos')

            return
        }

        toastr.success('Actualicé los datos de tu empresa', '¡Listo!')
        window.analytics.track('Empresa actualizada', {
            company
        })
    }

    const _validateForm = () => {
        if (nombre === '') { return false }
        if (razonSocial === '') { return false }
        if (regimenFiscal === '') { return false }
        if (rfc === '') { return false }
        if (cp === '') { return false }

        return true
    }

    const _handleFileUpload = (event) => {
        let files = event.target.files
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']

        if (types.every(type => files[0].type !== type)) {
            toastr.error('Solo se aceptan imágenes', 'Error')

            return
        }

        fileUpload(files[0]).then((response) => {
            setLogotipo(response.data.fileName)

            toastr.success('Subí tu logotipo', '¡Éxito!')
        }).catch(error => {
            console.log('fileUpload', error)
            toastr.error('No pude subir la imagen', 'Error')
        })
    }

    const fileUpload = (file) => {
        const formData = new FormData()
        formData.append('logotipo', file)

        return ApiService.uploadBrand(formData)
    }

    return (
        <React.Fragment>
            <Form id="account" type="POST" onSubmit={_handleSubmit}>
                <Card>
                    {logotipo && (
                        <Row>
                            <Col sm={12}>
                                <img alt="Timbrát" className="responsive" src={logotipo} width={200} />
                            </Col>
                        </Row>
                    )}

                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label htmlFor="name">
                                    Nombre Comercial
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={nombre}
                                    required
                                    onChange={event => setNombre(event.target.value)}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={6}>
                            <FormGroup>
                                <Label htmlFor="name">
                                    Razón Social
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={razonSocial}
                                    onChange={event => setRazonSocial(event.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label htmlFor="name">
                                    RFC
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={rfc}
                                    required
                                    onChange={event => setRFC(event.target.value)}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={6}>
                            <FormGroup>
                                <Label htmlFor="name">
                                    Régimen Fiscal
                                </Label>

                                <Select
                                    value={regimenFiscal}
                                    placeholder={`Selecciona un régimen fiscal`}
                                    onChange={(value) => {
                                        setRegimenFiscal(value)
                                    }}
                                    options={regimenFiscales}
                                    classNamePrefix="select2-selection"
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label htmlFor="email">
                                    Email
                                </Label>
                                <Input
                                    type="email"
                                    className="form-control"
                                    value={email}
                                    name="email"
                                    id="email"
                                    required
                                    onChange={event => setEmail(event.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label htmlFor="telefono">
                                    Teléfono
                                </Label>
                                <Input
                                    type="tel"
                                    className="form-control"
                                    value={telefono}
                                    name="telefono"
                                    id="telefono"
                                    required
                                    onChange={event => setTelefono(event.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label htmlFor="calle">
                                    Calle
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={calle}
                                    name="calle"
                                    id="calle"
                                    onChange={event => setCalle(event.target.value)}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={3}>
                            <FormGroup>
                                <Label htmlFor="noExterior">
                                    No. Exterior
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={noExterior}
                                    name="noExterior"
                                    id="noExterior"
                                    onChange={event => setNoExterior(event.target.value)}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={3}>
                            <FormGroup>
                                <Label htmlFor="noInterior">
                                    No. Interior
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={noInterior}
                                    name="noInterior"
                                    id="noInterior"
                                    onChange={event => setNoInterior(event.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={4}>
                            <FormGroup>
                                <Label htmlFor="cp">
                                    C.P.
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={cp}
                                    name="cp"
                                    id="cp"
                                    onChange={event => setCP(event.target.value)}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={4}>
                            <FormGroup>
                                <Label htmlFor="colonia">
                                    Colonia
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={colonia}
                                    name="colonia"
                                    id="colonia"
                                    onChange={event => setColonia(event.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Label htmlFor="ciudad">
                                    Ciudad
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={ciudad}
                                    name="ciudad"
                                    id="ciudad"
                                    onChange={event => setCiudad(event.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label htmlFor="estado">
                                    Estado
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={estado}
                                    name="estado"
                                    id="estado"
                                    onChange={event => setEstado(event.target.value)}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={6}>
                            <FormGroup>
                                <Label htmlFor="pais">
                                    País
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={pais}
                                    name="pais"
                                    id="pais"
                                    onChange={event => setPais(event.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label>Logotipo</Label>

                                <Input type="file"
                                    id="logotipo"
                                    onChange={_handleFileUpload}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className='form-actions'>
                        <Col sm={12}>
                            <Button
                                type="submit"
                                color="primary"
                                className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                            >
                                <i className="mdi mdi-refresh"></i> Actualizar
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    const { empresa } = state.Auth.profile

    return { empresa }
}

export default connect(
    mapStatetoProps, { updateCompany }
)(withRouter(Account))