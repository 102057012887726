import React from 'react'
import Breadcrumbs from '../../components/Global/Breadcrumbs'
import {
    Container,
} from 'reactstrap'

import DataTable from './components/Datatable'

const Index = () => {
    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Clientes"
                        breadcrumbItem={`Clientes`}
                        parentLink={`/clientes`}
                    />
                    <DataTable />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Index
