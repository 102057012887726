import React from 'react'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Profile from './Profile'

const Header = (props) => {
    return(
        <div className="navbar-profile">
            {(new Date() > new Date(props.Auth.profile.empresa.expirationDate)) && (
                <div className='account-expired-banner'>
                    Tu suscripción a Timbrát ha expirado. <Link to="/configuracion/pagos">Renuévala</Link> hoy mismo para que tu negocio no deje de facturar.
                </div>
            )}

            <Link className="navbar-timbrat-mobile" to="/">
                <img alt="Timbrát"  src="/assets/images/logo.svg" />
            </Link>
            <div className="navbar-profile-actions">
                <Profile profile={props.Auth.profile} />
            </div>
        </div>
    )
}

const mapStatetoProps = (state) => {
    const { Auth } = state
    return { Auth }
}

export default connect(mapStatetoProps)(Header)