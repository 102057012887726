import React, { useState } from 'react'

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import ApiService from '../../data/services/ApiService'

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { updateProfile } from '../../store/actions'

import { Form, FormGroup, Label, Input, Button, Row, Col, Card } from 'reactstrap'

const Account = (props) => {
    const [usuarioId] = useState(props.user._id)
    const [avatar, setAvatar] = useState(props.user.avatar)
    const [nombre, setNombre] = useState(props.user.nombre)
    const [usuario, setUsuario] = useState(props.user.usuario)
    const [email, setEmail] = useState(props.user.email)

    const _doUpdateProfile = async (e) => {
        e.preventDefault()

        if (!_validateForm()) {
            toastr.error(`Olvidaste llenar algunos campos`)

            return false
        }

        props.updateProfile({
            ...props.user,
            _id: usuarioId,
            avatar,
            nombre,
            usuario,
            email
        })

        const user = {
            avatar,
            nombre,
            usuario,
            email
        }

        const response = await ApiService.updateUser(usuarioId, user)

        if (!response.data.success) {
            toastr.error('No pude actualizar tus datos')

            return
        }

        window.analytics.track('Usuario actualizado', {
            user
        })
        toastr.success('Actualicé tu cuenta', '¡Listo!')
    }

    const _validateForm = () => {
        if (nombre === '') { return false }
        if (usuario === '') { return false }
        if (email === '') { return false }

        return true
    }

    const _handleFileUpload = (event) => {
        let files = event.target.files
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']

        if (types.every(type => files[0].type !== type)) {
            toastr.error('Solo puedo aceptar imágenes', 'Error')

            return
        }

        fileUpload(files[0]).then((response) => {
            setAvatar(response.data.fileName)

            toastr.success('Subí tu avatar', '¡Éxito!')
            window.analytics.track('avatar actualizado')
        }).catch(error => {
            console.log('fileUpload', error)
            toastr.error('No pude subir la imagen', 'Error')
        })
    }

    const fileUpload = (file) => {
        const formData = new FormData()
        formData.append('avatar', file)

        return ApiService.uploadUserAvatar(formData)
    }

    return (
        <React.Fragment>
            <Form id="account" type="POST" onSubmit={_doUpdateProfile}>
                <Card>
                    <Row>
                        <h3>Actualizar tus Datos</h3>

                        <Col sm={12}>
                            <FormGroup>
                                <Label>Avatar</Label>

                                <Input type="file"
                                    id="avatar"
                                    onChange={_handleFileUpload}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="nombre">
                                    Nombre
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={nombre}
                                    id="nombre"
                                    required
                                    onChange={event => setNombre(event.target.value)}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="usuario">
                                    Usuario
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={usuario}
                                    id="usuario"
                                    required
                                    onChange={event => setUsuario(event.target.value)}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="email">
                                    E-Mail
                                </Label>
                                <Input
                                    type="email"
                                    className="form-control"
                                    value={email}
                                    id="email"
                                    required
                                    onChange={event => setEmail(event.target.value)}
                                />
                            </FormGroup>

                            <Button
                                type="submit"
                                color="primary"
                                className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                            >
                                <i className="mdi mdi-refresh"></i> Actualizar Datos
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </React.Fragment>
    )
}


const mapStatetoProps = (state) => {
    const { user } = state.Auth.profile

    return { user }
}

export default connect(
    mapStatetoProps, { updateProfile }
)(withRouter(Account))