import React from 'react'

import Header from "../Global/Header"
import Sidebar from "../Global/Sidebar"
import Footer from "../Global/Footer"
import Content from "../Global/content"

import { withRouter } from "react-router-dom"

const Layout = (props) => {
    return(
        <React.Fragment>
            <Sidebar />
            <div className="content">
                <Header title="Timbrát" />
                <Content body={props.children} />
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default withRouter(Layout)