import React, { useState, useEffect, useContext } from 'react'
import { GeneralContext } from '../../../context/'
import ApiService from '../../../data/services/ApiService'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardBody,
    FormGroup,
    Label
} from "reactstrap"

const FormPage = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [id, setId] = useState()
    const [nombre, setNombre] = useState()
    const [email, setEmail] = useState()
    const [usuario, setUsuario] = useState()
    const [password, setPassword] = useState()

    useEffect(() => {
        if (props.data !== undefined) { 
            const { data } = props

            setId(data._id)
            setNombre(data.nombre)
            setEmail(data.email)
            setUsuario(data.usuario)
        }
    }, [props])

    const _handleSubmit = async (e) => {
        e.preventDefault()

        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')

            return
        }

        setLoading(true)
        let data = {
            nombre,
            email,
            usuario,
            password
        }

        if (props.data !== undefined) { 
            await _updateUser(id, data)
            props.history.push('/configuracion/usuarios')

            return false
        }

        await _createUser(data)
        props.history.push('/configuracion/usuarios')
    }

    const _updateUser = async (id, data) => {
        setLoading(true)

        try {
            const response = await ApiService.updateUser(id, data)

            if (!response.data.success) {
                toastr.error(response.data.message, 'Error')

                return false
            }

            window.analytics.track('Usuario Actualizado')
            toastr.success(response.data.message, '¡Éxito!')
        } catch(error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const _createUser = async (data) => {
        setLoading(true)

        try {
            const response = await ApiService.createUser(data)

            if (!response.data.success) {
                toastr.error(response.data.message, 'Error')

                return false
            }

            window.analytics.track('Usuario Agregado')
            toastr.success(response.data.message, '¡Éxito!')
        } catch(error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const _validateForm = () => {
        if (nombre === '') { return false }
        if (email === '') { return false }
        if (usuario === '') { return false }
        if (password === '') { return false }

        return true
    }

    return (
        <React.Fragment>
            <Form id="lugares" method="POST" onSubmit={ _handleSubmit }>
                <Row>
                    <Col sm={12}>
                        <Card style={{textAlign: 'right'}}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> Guardar
                                </Button>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label htmlFor="nombreLugar">
                                                Nombre
                                            </Label>

                                            <Input
                                                id="nombreLugar"
                                                name="nombreLugar"
                                                type="text"
                                                className="form-control"
                                                value={nombre}
                                                onChange={event => setNombre(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label htmlFor="nombreLugar">
                                                E-Mail
                                            </Label>

                                            <Input
                                                id="email"
                                                name="email"
                                                type="email"
                                                className="form-control"
                                                value={email}
                                                onChange={event => setEmail(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>
                                                Usuario
                                            </Label>

                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={usuario}
                                                onChange={event => setUsuario(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>
                                                Contraseña
                                            </Label>

                                            <Input
                                                type="password"
                                                className="form-control"
                                                value={password}
                                                onChange={event => setPassword(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default FormPage