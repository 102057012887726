import React, { useState } from "react"
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap"

import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import ApiService from '../../data/services/ApiService'

const ProfileMenu = (props) => {
    const [menu, setMenu] = useState(false)

    const toggle = () => {
        setMenu(!menu)
    }

    return(
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={ toggle }
                className="d-inline-block"
                onClick={() => {
                    window.analytics.track('Perfil: Menú')
                }}
            >
                <DropdownToggle
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    {props && props.avatar && (
                        <img className="rounded-circle header-profile-user thumbnail"
                            src={props.avatar}
                            alt={props.nombre}
                        />
                    )}

                    {props && !props.avatar && (
                        <img className="rounded-circle header-profile-user thumbnail"
                            src={`/assets/images/usuario.png`}
                            alt={props.nombre}
                        />
                    )}

                    <span className="header-item-profile-name">
                        {props && (
                            <span>{props.nombre}</span>
                        )}
                    </span>
                    <i className="header-item-chevron mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem tag="a" href="/configuracion" onClick={() => {
                                window.analytics.track('Ir a Configuraciones')
                            }}>
                        <i className="bx bx-wrench font-size-17 align-middle mr-1"></i>
                        Configuraciones
                    </DropdownItem>
                    
                    <div className="dropdown-divider"></div>
                    <Link to="/logout" className="dropdown-item" onClick={() => {
                                window.analytics.track('Cerrar Sesión')
                            }}>
                        <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                        <span>Cerrar Sesión</span>
                    </Link>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    return {
        nombre: state.Auth.profile.user ? state.Auth.profile.user.nombre : '',
        avatar: state.Auth.profile.user ? state.Auth.profile.user.avatar : ''
    }
}

export default connect(
    mapStatetoProps, {}
)(withRouter(ProfileMenu))
