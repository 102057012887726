import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Row, Col, CardTitle, CardBody, Table } from 'reactstrap'
import * as moment from 'moment'
import PaymentForm from './PaymentForm'

const CurrentPlan = (props) => {
    const [currentPlan, setCurrentPlan] = useState(null)
    const [expired, setExpired] = useState(false)

    useEffect(() => {
        setCurrentPlan(props.currentPlan)
        setExpired(props.expired)
    }, [])

    useEffect(() => {
        const plan = props.plans.find(p => p._id === props.empresa.plan)
        setCurrentPlan(plan)
        setExpired(new Date() > new Date(props.empresa.expirationDate))
    }, [props])

    return (
        <>
            {currentPlan && (
                <>
                    <Row>
                        <Col sm={12}>
                            <CardTitle>Plan actual para {props.empresa.nombre}</CardTitle>

                            <CardBody>
                                <Table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <strong>Timbrát {currentPlan.nombre}</strong><br />
                                                Miembro desde {moment(props.empresa.createdAt).format('DD/MM/YYYY')}
                                            </td>
                                            <td>
                                                <ul>
                                                    <li>{currentPlan.timbres} facturas</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <strong>$ {currentPlan.precio}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Col>
                    </Row>

                    {expired && (
                        <Row>
                            <Col sm={12}>
                                <PaymentForm currentPlan={currentPlan} plans={props.plans} />
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </>
    )
}

const mapStatetoProps = (state) => {
    const { empresa } = state.Auth.profile

    return { empresa }
}

export default connect(
    mapStatetoProps, {}
)(withRouter(CurrentPlan))