import React from "react"
import { Redirect } from "react-router-dom"

import Login from '../pages/usuarios/Login'
import Logout from '../pages/usuarios/Logout'
import RecuperarPass from '../pages/usuarios/recoverpassword'
import ConfirmarRecuperarPass from '../pages/usuarios/ConfirmRecover'

import Clientes from '../pages/clientes/Index'
import AgregarCliente from '../pages/clientes/Create'
import EditarCliente from '../pages/clientes/Edit'

import Servicios from '../pages/servicios/Index'
import AgregarServicio from '../pages/servicios/Create'
import EditarServicio from '../pages/servicios/Edit'

import Usuarios from '../pages/usuarios/Index'
import UsuariosCreate from '../pages/usuarios/Create'
import UsuariosEdit from '../pages/usuarios/Edit'

import NewInvoice from '../pages/facturas/Create'
import Invoices from '../pages/facturas/Index'
import ExpressInvoice from '../pages/facturas/CreateExpress'

import Lugares from '../pages/lugares/Index'
import AgregarLugares from '../pages/lugares/Create'
import EditarLugares from '../pages/lugares/Edit'

import Certificados from '../pages/certificados/Index'
import AgregarCertificados from '../pages/certificados/Create'

// import Suscription from '../components/configuration/payment/index'
// import SuscriptionPayment from '../components/configuration/payment/CreditCard'
// import SuscriptionCancel from '../components/configuration/payment/Cancel'

import Reportes from '../pages/reportes/Index'
import Configuration from '../pages/configuraciones/Index'

const authProtectedRoutes = [
    { path: "/facturas/express", component: ExpressInvoice },
    { path: "/facturas/nueva", component: NewInvoice },
    { path: "/facturas", component: Invoices },
    
    { path: "/clientes/editar/:id", component: EditarCliente },
    { path: "/clientes/agregar", component: AgregarCliente },
    { path: "/clientes", component: Clientes },
    
    { path: "/servicios/editar/:id", component: EditarServicio },
    { path: "/servicios/agregar", component: AgregarServicio },
    { path: "/servicios", component: Servicios },

    { path: "/lugares/editar/:id", component: EditarLugares },
    { path: "/lugares/nuevo", component: AgregarLugares },
    { path: "/lugares", component: Lugares },

    { path: "/certificados/nuevo", component: AgregarCertificados },
    { path: "/certificados", component: Certificados },

    { path: "/usuarios/editar/:id", component: UsuariosEdit },
    { path: "/usuarios/nuevo", component: UsuariosCreate },
    { path: "/usuarios", component: Usuarios },

    { path: "/lugares/editar/:id", component: EditarLugares },
    { path: "/lugares/nuevo", component: AgregarLugares },
    { path: "/lugares", component: Lugares },

    { path: "/configuracion/:route", component: Configuration },
    { path: "/configuracion", component: Configuration },
    { path: "/reportes", component: Reportes },
    { path: "/logout", component: Logout },
    
    // this route should be at the end of all other routes
    { path: "/", exact: true, component: () => <Redirect to="/facturas" /> },
]

const publicRoutes = [
    { path: "/login", component: Login },
    { path: "/recuperar-password", component: RecuperarPass },
    { path: "/confirmar-recuperar-password/:token", component: ConfirmarRecuperarPass }
]

export { authProtectedRoutes, publicRoutes }
