import React, { useState, useEffect, useContext } from 'react'
import {
    Row,
    Col,
    Input,
    Card,
    CardBody,
    CardTitle,
    CardFooter
} from 'reactstrap'

import { Link } from 'react-router-dom'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { GeneralContext } from '../../../context/'
import ApiService from '../../../data/services/ApiService'
import CurrencyFormat from 'react-currency-format'

const Table = () => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        (async () => {
            await fetchData()
        })()

        async function fetchData() {
            setLoading(true)
            const response = await ApiService.getServices()

            setData(response.data.data)
            setFilteredData(response.data.data)
            setLoading(false)
        }
    }, []) // eslint-disable-line

    const _filterData = (e) => {
        const keystroke = e.target.value.toLowerCase()
        const filteredData = data.filter((item) => {
            const clave = item.claveProdServ || ''
            const descripcion = item.descripcion || ''
            const noIdentificacion = item.noIdentificacion || ''

            return noIdentificacion.toLowerCase().includes(keystroke) ||
                descripcion.toLowerCase().includes(keystroke) ||
                clave.toLowerCase().includes(keystroke)
        })

        setFilteredData(filteredData)
    }

    const _deleteItem = async (id) => {
        setLoading(true)

        try {
            const result = await ApiService.deleteService(id)

            if (!result.data.success) {
                toastr.error(result.data.message, 'Error')

                return false
            }

            const filtered = await data.filter((v) => { return v._id !== id })

            setData(filtered)
            setFilteredData(filtered)

            toastr.success(result.data.message, '¡Éxito!')
            window.analytics.track('Servicio Eliminado')
        } catch (error) {
            toastr.error(`Tuve un problema de conexión, por favor inténtalo de nuevo`, 'Error')
        } finally {
            setLoading(false)
        }
    }

    return(
        <React.Fragment>
            <Row className="mb-2">
                <Col sm="4">
                    <div className="search-box mr-2 mb-2 d-inline-block">
                        <div className="position-relative">
                            <Input type="text" className="form-control"
                                placeholder="Buscar ..."
                                onKeyUp={(e) => _filterData(e)}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                        </div>
                    </div>
                </Col>
                <Col sm="8">
                    <div className="text-sm-right">
                        <Link to={`/servicios/agregar`} className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2">
                            <i className="mdi mdi-plus mr-1"></i> Agregar Servicio
                        </Link>
                    </div>
                </Col>
            </Row>

            {filteredData && filteredData.map((item, key) => (
                <Col {...key} sm={12} xs={12} md={6}>
                    <Card>
                        <CardTitle>{item.descripcion}</CardTitle>
                        <CardBody>
                            <Row>
                                <Col sm={4}><strong>No. Identificación:</strong> {item.noIdentificacion}</Col>
                                <Col sm={4}><strong>Clave de Producto y Servicio:</strong> {item.claveProdServ}</Col>
                                <Col sm={4}>
                                    <strong>Precio: </strong>

                                    <CurrencyFormat
                                        value={item.valorUnitario}
                                        displayType="text"
                                        thousandSeparator
                                        prefix="$"
                                        decimalScale={2}
                                    />
                                </Col>
                            </Row>
                        </CardBody>

                        <CardFooter className="bg-transparent border-top">
                            <div className="d-flex font-size-20">
                                <div className="flex-fill">
                                    <Link id={'edit'} to={`/servicios/editar/${item._id}`}>
                                        <i className="mdi mdi-pencil"></i> Editar
                                    </Link>
                                </div>

                                <div className="flex-fill">
                                    <button
                                        id={'delete'}
                                        type="button"
                                        className="btn btn-link p-0 m-0 d-inline align-baseline"
                                        onClick={() => _deleteItem(item._id)}>
                                        <i className="mdi mdi-trash-can font-size-16 text-danger mr-2"></i> Eliminar
                                    </button>
                                </div>
                            </div>
                        </CardFooter>
                    </Card>
                </Col>
            ))}
        </React.Fragment>
    )
}

export default Table