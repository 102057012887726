import React, { useState, useContext } from 'react'
import { GeneralContext } from '../../../context/'
import ApiService from '../../../data/services/ApiService'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import {
    Form,
    Button,
    Row,
    Col,
    Input,
    Card,
    CardTitle,
    CardBody,
    FormGroup,
    Label
} from "reactstrap"

const FormPage = (props) => {
    const generalContext = useContext(GeneralContext)
    const { setLoading } = generalContext

    const [noCertificado, setNoCertificado] = useState()
    const [password, setPassword] = useState()
    // const [certificado, setCerFile] = useState()
    // const [llave, setKeyFile] = useState()

    const _handleSubmit = (e) => {
        e.preventDefault()

        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')

            return
        }

        _createCertificado()
    }

    const _createCertificado = async () => {
        setLoading(true)

        try {
            const response = await ApiService.createCertificado({
                noCertificado,
                password
            })

            if (!response.data.success) {
                toastr.error(response.data.message, 'Error')

                return false
            }

            window.analytics.track('CSD publicado')
            toastr.success(response.data.message, '¡Éxito!')
            props.history.push('/configuracion/certificados')
        } catch(error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const _validateForm = () => {
        if (noCertificado === '') { return false }
        if (password === '') { return false }

        return true
    }

    return(
        <React.Fragment>
            <Form id="lugares" method="POST" onSubmit={ _handleSubmit }>
                <Row>
                    <Col sm={12}>
                        <Card style={{textAlign: 'right'}}>
                            <CardBody>
                                <Button
                                    onClick={() => { window.history.go(-1) }}
                                    className="btn btn-default btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                >
                                    <i className={`mdi mdi-check`}></i> Guardar
                                </Button>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <CardTitle>Nota Importante</CardTitle>

                                <p>
                                    No te olvides de enviarnos por correo (hola@timbrat.com.mx) los archivos
                                    de tu certificado CSD para que los podamos activar.
                                </p>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>
                                                No. Certificado
                                            </Label>

                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={noCertificado}
                                                onChange={event => setNoCertificado(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label>
                                                Password
                                            </Label>

                                            <Input
                                                type="password"
                                                className="form-control"
                                                value={password}
                                                onChange={event => setPassword(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default FormPage