import { takeEvery, fork, put, all } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, UPDATE_PASSWORD } from "./actionTypes"
import { loginSuccess, logoutUserSuccess, updatePasswordSuccess, apiError } from "./actions"

import ApiService from '../../data/services/ApiService'
import { reactLocalStorage } from "reactjs-localstorage"

function* loginUser({ payload: { user, history, from } }) {
    try {
        const response = yield ApiService.login({
            usuario: user.user,
            password: user.password
        })

        if (!response.data.success) {
            yield put(apiError('invalid login'))

            return false
        }

        reactLocalStorage.set("token", response.data.token)
        reactLocalStorage.set("profile", response.data.profile)

        window.analytics.identify(response.data.profile.user._id, {
            name: `${response.data.profile.user.nombre}`,
            email: response.data.profile.user.email,
            empresa: response.data.profile.empresa.nombre
        })

        yield put(loginSuccess(response.data.profile))

        history.push(from || '/')
    } catch (error) {
        yield put(apiError(error))
    }
}

function* updatePassword({ payload: { data } }) {
    try {
        const response = yield ApiService.updatePassword({
            password: data.password
        })

        if (!response.data.success) {
            yield put(apiError(`can't update password`))

            return false
        }

        yield put(updatePasswordSuccess())
    } catch(error) {
        yield put(apiError(error))
    }
}

function* logoutUser({ payload: { history } }) {
    reactLocalStorage.clear()

    yield put(logoutUserSuccess())

    history.push("/login")
}

export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

export function* whatUpdatePassword() {
    yield takeEvery(UPDATE_PASSWORD, updatePassword)
}

function* authSaga() {
    yield all([fork(watchUserLogin), fork(watchUserLogout), fork(whatUpdatePassword)])
}

export default authSaga
