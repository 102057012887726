import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Row, Col, Card, CardTitle, CardBody, Button } from 'reactstrap'
import * as moment from 'moment'
import ApiService from '../../data/services/ApiService'
import CurrentPlan from './CurrentPlan'
import Invoices from './Invoices'
import toastr from 'toastr'
import PaymentForm from './PaymentForm'

const Index = (props) => {
    const [plans, setPlans] = useState([])
    const [expired, setExpired] = useState(false)
    const [showPaymentForm, setShowPaymentForm] = useState(false)
    const [currentPlan, setCurrentPlan] = useState({})

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const response = await ApiService.getPlans()
                setPlans(response.data.plans)
            } catch (error) {
                toastr.error('Error fetching plans')
            }
        }

        fetchPlans()
    }, [])

    useEffect(() => {
        const plan = plans.find(p => p._id === props.empresa.plan)
        setCurrentPlan(plan)
        setExpired(new Date() > new Date(props.empresa.expirationDate))
    }, [props])

    useEffect(() => {
        const plan = plans.find(p => p._id === props.empresa.plan)

        setCurrentPlan(plan)
        setExpired(new Date() > new Date(props.empresa.expirationDate))
    }, [plans])

    const generateBillingPeriodText = (expirationDateISO) => {
        const expirationDate = moment(expirationDateISO)

        // Format the expiration date as "Month Day Year"
        const formattedEndDate = expirationDate.format('DD/MMMM/YYYY')

        // Calculate the start date as 1 month before expirationDate
        const startDate = expirationDate.clone().subtract(1, 'month')

        // Format the start date as "Month Day Year"
        const formattedStartDate = startDate.format('DD/MMMM/YYYY')

        // Generate the billing period text
        const billingPeriodText = `Tu periodo actual de facturación va desde el ${formattedStartDate} al ${formattedEndDate}`

        return billingPeriodText
    }

    return (
        <React.Fragment>
            <Card sm={12}>
                <CardTitle>
                    <Row>
                        <Col sm={6}>Facturación para {props.empresa.nombre}</Col>
                        <Col sm={6} style={{ textAlign: 'right' }}>{props.empresa.tipoPlan} {expired && (<>(<span style={{ color: '#B52C21' }}>expirado</span>)</>)}</Col>
                    </Row>
                </CardTitle>
                <CardBody>
                    {expired && (
                        <>
                            <Row>
                                <Col sm={12}>
                                    <strong>Tu suscripción ha expirado</strong>
                                    <p>No te dejes que tu empresa se quede sin facturar, renueva tu plan hoy mismo.</p>
                                </Col>
                            </Row>

                            <CurrentPlan {...props } currentPlan={currentPlan} plans={plans}  />
                        </>
                    )}

                    {new Date() < new Date(props.empresa.expirationDate) && (
                        <>
                            {generateBillingPeriodText(props.empresa.expirationDate)}
                        </>
                    )}
                </CardBody>
            </Card>

            {!expired && plans && currentPlan && (
                <React.Fragment>
                    <Card>
                        <CurrentPlan {...props } currentPlan={currentPlan} plans={plans} expired={expired}  />

                        <Button 
                            onClick={(e) => {
                                e.preventDefault()

                                setShowPaymentForm(!showPaymentForm)
                            }}
                            className='btn btn-secondary'>
                            Cambiar de plan
                        </Button>

                        <Row style={{ padding: '24px 0'}}>
                            <Col sm={12}>
                                {showPaymentForm && <PaymentForm currentPlan={currentPlan} plans={plans} />}
                            </Col>
                        </Row>
                    </Card>
                </React.Fragment>
            )}

            <Card>
                <Invoices />
            </Card>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    const { empresa } = state.Auth.profile

    return { empresa }
}

export default connect(
    mapStatetoProps, {}
)(withRouter(Index))