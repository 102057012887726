import React, { useState } from 'react'
import Modal from 'react-modal'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
}

export default ({ isOpen, onAfterOpen, onRequestClose, contentLabel, title, body, footer, styles }) => {
    const [open, setOpen] = useState(isOpen)
    const _closeModal = () => { setOpen(false) }

    return(
        <Modal
            isOpen={open}
            onAfterOpen={onAfterOpen || function noRefCheck() {} }
            onRequestClose={onRequestClose || _closeModal}
            contentLabel={contentLabel}
            style={customStyles || styles}
            className="jsmodal-dialog"
        >
            <div className='jsmodal-content'>
                <div className="jsmodal-header">
                    <h4 className="jsmodal-title">{title}</h4>
                    <button type="button" className='btn-close' onClick={_closeModal}>
                        <span aria-hidden="true">&times;</span>
                        <span className="sr-only">Cerrar</span>
                    </button>
                </div>

                <div className='jsmodal-body'>
                    {body}
                </div>

                <div className='jsmodal-footer'>
                    {footer}
                </div>
            </div>
        </Modal>
    )
}