import React, { useRef, useState, useEffect, useContext } from 'react'
import { GeneralContext } from '../../context/'
import ApiService from '../../data/services/ApiService'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import Breadcrumbs from '../../components/Global/Breadcrumbs'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { isEmpty, head } from 'lodash'

import {
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    FormGroup,
    Container
} from "reactstrap"

const Index = () => {
    const generalContext = useContext(GeneralContext)
    const { loading, setLoading } = generalContext

    const autosuggestRef = useRef(null)
    const [autosuggest, setAutosuggest] = useState()
    const [unidades, setUnidades] = useState([])

    useEffect(() => {
        (async () => {
            await fetchUnidades()
        })()

        // eslint-disable-next-line
    }, [])

    const _addItem = async (item) => {
        setAutosuggest([])
        const unidad = head(item)

        try {
            setLoading(true)
            const response = await ApiService.createUnidad({
                clave: unidad.clave,
                descripcion: unidad.descripcion
            })

            window.analytics.track('Unidad Agregada')
            toastr.success(response.data.message, '¡Chócalas!')
            fetchUnidades()
        } catch (error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
        
        autosuggestRef.current.clear()
    }

    const _deleteItem = async (id) => {
        try {
            setLoading(true)
            const response = await ApiService.deleteUnidad(id)

            const filtered = await unidades.filter((v) => { return v._id !== id })
            setUnidades(filtered)

            window.analytics.track('Unidad Eliminada')
            toastr.success(response.data.message, '¡Chócalas!')
        } catch(error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const fetchUnidades = async () => {
        setLoading(true)

        try {
            const response = await ApiService.getUnidades()
            const { data } = response.data

            setUnidades(data)
        } catch (error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    const searchInSAT = async (keyword) => {
        try {
            setLoading(true)
            const response = await ApiService.searchForUnidadInSAT(keyword)
            const { data, success } = response.data

            if (success) {
                if (isEmpty(data)) {
                    autosuggestRef.current.focus()
                }

                setAutosuggest(data)
            }

            setLoading(false)
        } catch (error) {
            toastr.error(`No pude conectarme con el servidor`, 'Problema de conexión')
        } finally {
            setLoading(false)
        }
    }

    return (
        <React.Fragment>
            <div id="mainContent" className="page mainContent">
                <Container fluid>
                    <Breadcrumbs
                        title="Configuraciones"
                        breadcrumbItem={`Unidades`}
                        parentLink={`/configuracion/unidades`}
                    />

                    <Card>
                        <CardTitle>Selecciona del catálogo del SAT, las que vayas a utilizar con mayor frecuencia.</CardTitle>

                        <Row>
                            <Col sm={7}>
                                <FormGroup>
                                    <AsyncTypeahead
                                        filterBy={() => true}
                                        id="codigo"
                                        ref={autosuggestRef}
                                        isLoading={loading}
                                        minLength={3}
                                        labelKey="clave"
                                        onChange={_addItem}
                                        onSearch={searchInSAT}
                                        options={autosuggest}
                                        placeholder="Busca en catálogo del SAT..."
                                        searchText="Buscando..."
                                        promptText="Buscando..."
                                        useCache={true}
                                        caseSensitive
                                        renderMenuItemChildren={(i, props) => (
                                            <React.Fragment>
                                                <span>({i.clave}) {i.descripcion}</span>
                                            </React.Fragment>
                                        )}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Card>

                    <Card>
                        <CardTitle>Mis Favoritas</CardTitle>

                        <CardBody>
                            <ul className='unidades_list'>
                                {unidades && unidades.map((item, key) => {
                                    return (
                                        <li {...key}>
                                            <span><button className="btn btn-danger" type="button" onClick={() => _deleteItem(item._id)}><i className='mdi mdi-trash-can'></i></button> ({item.clave}) {item.descripcion}</span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Index