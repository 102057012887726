import React from 'react'
import Breadcrumbs from '../../components/Global/Breadcrumbs'
import { Container } from 'reactstrap'
import Form from './components/Form'

const Create = (props) => {
    return(
        <React.Fragment>
            <div id="mainContent" className="page mainContent">
                <Container fluid>
                    <Breadcrumbs
                        title="Facturación"
                        icon={`fa fa-qrcode`}
                        breadcrumbItem={`Nueva Factura`}
                        parentLink={`/facturas`}
                    />

                    <Form {...props} />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Create