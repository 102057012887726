import React, { useState, useEffect } from 'react'

import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap"

import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { loginUser, apiError } from "../../store/actions"
import toastr from 'toastr'

const Login = (props) => {
    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() => {
        const { error } = props

        if (error) {
            toastr.error('Utilizaste un usuario o password incorrecto', `Espera`)
            props.apiError('')
        }

        // eslint-disable-next-line
    }, [props.error])

    const _handleSubmit = (e) => {
        e.preventDefault()

        if (!_validateNull()) {
            toastr.error('No olvides ingresar tu usuario y password')

            return false
        }

        let from = null
        if (props.location.state) {
            from = props.location.state.from.pathname
        }

        props.loginUser({
            user, password
        }, props.history, from)
    }

    const _validateNull = () => {
        if (user === '') { return false }
        if (password === '') { return false }

        return true
    }

    return(
        <React.Fragment>
            <div className="login">
                <div className="wrapper grid-layout">
                    <Form id="login" type="POST" onSubmit={ _handleSubmit }>
                        <div className="logo">
                            <img alt="Timbrát" src="./assets/images/logo.svg" />
                        </div>
                        <div className="form">
                            <h1>Bienvenido.</h1>
                            <Row>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Label htmlFor="name">
                                            Usuario
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            value={user}
                                            onChange={event => setUser(event.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Label htmlFor="name">
                                            Password
                                        </Label>
                                        <Input
                                            type="password"
                                            className="form-control"
                                            value={password}
                                            onChange={event => setPassword(event.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className='form-actions'>
                                <Col sm={12}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                    >
                                        Iniciar Sesión
                                    </Button>
                                </Col>

                                <Link to="/recuperar-password" onClick={() => {
                                    window.analytics.track('Ir a Recuperar Contraseña')
                                }}>¿Olvidaste tu contraseña?</Link>.
                            </Row>

                            <Row>
                                <Col sm={12} className="login-signup">
                                    <h2>¿Aún no tienes cuenta?</h2>

                                    <a href="https://timbrat.com.mx/registro/" onClick={() => {
                                        window.analytics.track('Ir a Registro')
                                    }}>Crea tu cuenta hoy mismo</a>.
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12} className="login-copyright">
                                    <p>Copyright © {new Date().getFullYear()}. Todos los Derechos Reservados.</p>
                                    <p>Hecho con amor por <a target="_blank" rel="noopener noreferrer" href="https://juvasoft.com/?utm_source=timbrat&utm_medium=login">Juvasoft</a></p>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = (state) => {
    const { error } = state.Auth
    return { error }
}

export default withRouter(
    connect(mapStatetoProps, { loginUser, apiError })(Login)
)